<template>
	<div v-if="device.device" class="device" :class="'device--' + device.name">
		<div class="flex justify-center items-center">
			<div class="device__img-wrap">
				<img class="device__img mx-auto" width="264" height="356"  :src="require('@/assets/devices/' + device.name + '.png')">
			</div>
			<div class="device__info">
				<div class="font-bold text-24 mb-20">{{ device.title }}</div>
				<button @click="routeAndTrackDevice" class="btn btn-secondary-dark">
					Alege
				</button>
			</div>
		</div>
		<div class="device__border"></div>
	</div>
</template>

<script>
export default {
	name: "DeviceItem",
	props: ['device'],
	methods: {
		tracking(route) {
			dataLayer.push({
				'event': 'gtmZoneEvent', 
				'eventCategory': 'iqos_pocket', 
				'eventAction': `alege_${route}`, 
				'eventLabel': route
			});
		},
		routeAndTrackDevice(route) {
			var route = this.device.name;
			this.tracking(route);
			this.$router.push({ 'path': '/alege-dispozitiv/' + route });
		}
	}
};
</script>
