<template>
	<div ref="cc" class="container-custom">
		<!-- <Header /> -->

		<div class="ip-step ip-step--choose text-center">

			<div class="mx-auto md:h-full h-auto md:pt-30">
				<carousel 
					ref="devices" 
					:per-page="perPage" 
					:mouse-drag="false" 
					:speed="600" 
					:paginationEnabled="false"
					@mounted="onCarouselReady"
				>
					<!--new devices-->
					<slide class="w-100 md:px-20">
						<div class="max-w-[500px] md:max-w-[776px] mx-auto w-full">
							<div class="device-wrap">
								<device-item
									v-for="device in devicesNew" :key="device.name"
									ref="firstSlideDevices"
									:device="device"
								></device-item>
							</div>
						</div>

						<div class="slide-footer">
							<span class="slide-footer__btn" @click="slideControl('next')">
								<span>Dispozitive anterioare</span>
								<icon-arrow-right class="inline-block ml-10 relative top-[2px]"></icon-arrow-right>
							</span>
						</div>

					</slide>
					<!--end new devices-->

					<!--old devices-->
					<slide class="w-100 md:px-20">
						<div class="max-w-[500px] md:max-w-[776px] mx-auto">
							<div class="device-wrap">
								<device-item
									v-for="device in devicesOld" :key="device.name"
									ref="secondSlideDevices"
									:device="device"
								></device-item>
							</div>
						</div>

						<div class="slide-footer">
							<span class="slide-footer__btn" @click="slideControl('prev')">
								<icon-arrow-left class="inline-block mr-10"></icon-arrow-left>
								<span>Înapoi</span>
							</span>
						</div>

					</slide>
					<!--end old devices-->
				</carousel>
			</div>
		</div>
	</div>
</template>

<script>
import DeviceItem from "@/views/Partials/DeviceItem";
import IconArrowLeft from "@/views/Partials/IconArrowLeft";
import IconArrowRight from "@/views/Partials/IconArrowRight";
import config from "@/config";

import { Carousel } from "vue-carousel";

export default {
	name: "ChooseDevice",
	components: {
		DeviceItem,
		IconArrowLeft,
		IconArrowRight,
		Carousel
	},

	data() {
		return {
			config,
			isMobile: (window.innerWidth < 768)
		}
	},

	computed: {
		devicesNew() {
			return this.config.devices.filter(device => !device.isOld);
		},
		devicesOld() {
			return this.config.devices.filter(device => device.isOld);
		},
		perPage () {
			return this.isMobile ? 1 : 1;
		}
	},
	
	methods: {

		slideControl (action) {
			let page = 0
			if (action === 'next') {
				page = this.$refs.devices.getNextPage()
			} else {
				page = this.$refs.devices.getPreviousPage()
			}
			this.$refs.devices.goToPage(page)
		},

		onWindowResize () {
			this.isMobile = (window.innerWidth < 768);
		},

		onCarouselReady () {
			this.$nextTick(() => {
				var devicesFirst = this.$refs['firstSlideDevices'].map(d => d.$el);
				var devicesSecond = this.$refs['secondSlideDevices'].map(d => d.$el);
				TweenMax.staggerFromTo(devicesFirst, .5, { opacity: 0, y: 20 }, { opacity: 1, y: 0, delay: 0.2}, 0.1);
				TweenMax.staggerFromTo(devicesSecond, .5, { opacity: 0, y: 20 }, { opacity: 1, y: 0, delay: 0.5}, 0.1);
			});
		}

	},
	mounted: function() {
		this.$store.commit('setProp', { prop: 'showAppHeader', value: true });
		window.addEventListener('resize', this.onWindowResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onWindowResize);
	}
};
</script>
