<template>
    <!--accordion-->
    <div class="accordion" :class="cssClasses">
        <div class="accordion__title" @click="toggle">
            <div class="inner">
                <slot name="accTitle"></slot>
            </div>
        </div>
        <transition name="accordion">
            <div v-show="isOpen" class="accordion__content">
                <slot name="accContent"></slot>
            </div>
        </transition>
    </div>
    <!--end accordion-->
</template>


<script>

export default {
    name: 'Accordion',
    props: [
        'type',
        'group',
        'uid',
    ],
    data() {
        return {
            isOpen: false
        };
    },
    computed: {
        cssClasses() {
            var cssClasses = {
                active: this.isOpen
            }
            cssClasses['accordion--type-' + this.type] = true;
            return cssClasses;
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
            if (this.group && this.isOpen) {
                this.$root.$emit('accordionToggled', {
                    component: this,
                    group: this.group
                });
            }
        },
        collapse() {

        }
    },
    mounted() {
        this.$root.$on('accordionToggled', (data) => {
            if (data.group === this.group && data.component !== this) {
                this.isOpen = false;
            }
        })

        // expand accordion based on menu navigation
        if (this.uid === this.$route.hash.replace('#','')) {
            this.isOpen = true;
        }
    },
    beforeDestroy() {
        this.$root.$off('accordionToggled');
    }
};
</script>
