<template>
	<div class=" px-0 md:px-12 mt-20" >
		<div v-if="isIluma && this.$route.path !== '/diferente' && this.$route.path !== '/sfaturi'" class="flex flex-wrap sm:flex-nowrap">
			<div  class="bg-turquoise-90 w-full sm:w-1/2 flex flex-col items-end  md2:flex-row h-auto p-20 mx-10  pb-0 md:pt-0 mb-30 text-left min-h-[265px]" v-for="banner in banners" :key="banner.title">
				<div class="w-full md2:w-3/5 relative overflow-hidden flex flex-col justify-between  pb-20 md:px-20 md:py-40 md:min-h-[250px]">
					<h1 class="pb-20 font-bold text-24">
						<span class="text-soft-white">{{ banner.titleW }}</span>
						{{ banner.title }}
					</h1>
					<div v-show="banner.id == 1">
						<router-link to="/diferente" class="btn btn-primary-dark btn-primary-dark-alt">
							Descoperă
						</router-link>
					</div>
					<div v-show="banner.id == 2">
						<router-link to="/sfaturi" class="btn btn-primary-dark btn-primary-dark-alt">
							Urmărește video
						</router-link>
					</div>
				</div>
				<div class="w-full md2:w-2/5 mt-auto  text-18">
					<img v-if="!showIlumaPrime" :src="require('@/assets/banners/' + banner.img + '.png')" :alt="banner.title" class="mx-auto" />
					<img v-if="showIlumaPrime" :src="require('@/assets/banners/' + banner.imgPrime + '.png')" :alt="banner.title" class="mx-auto" />

				</div>
			</div>
		</div>
		<div v-if="!isIluma && showBanner && this.$route.path !== '/diferente' && this.$route.path !== '/diferente-iqos3duo' && this.$route.path !== '/diferente-iqos24plus'"  class="mx-10">
			<div class="bg-turquoise-90 w-full flex flex-col md1:flex-row items-center justify-center h-full px-20 md1:px-40 pt-10" v-for="bannerWide in bannersWide" :key="bannerWide.title">
				<div class="text-slate w-full md1:w-[35%] text-left py-20 ">
					<h1 class="text-24 font-bold pb-10">
						<span class="text-soft-white">{{ bannerWide.titleW }}</span>
						{{ bannerWide.title }}
					</h1>
					<p class="text-18">
						{{ bannerWide.descr }}
					</p>
				</div>
				<div class="order-3 md1:order-2 md1:w-[45%] h-full mt-auto">
					<div class="px-20">
						<img v-if="isMobile" :src="require('@/assets/banners/' + bannerWide.img1 + '.png')" :alt="bannerWide.title"/>
						<img v-else :src="require('@/assets/banners/' + bannerWide.img2 + '.png')" :alt="bannerWide.title" class=" w-full"/>
					</div>
				</div>
				<div class="w-full md1:w-[20%] order-2 md1:order-3 text-left md1:text-center mb-40 md1:mb-0 md1:pb-0 ">
					<router-link v-if="showIqos3Duo && !showIqos24Plus" to="/diferente-iqos3duo"  class="btn btn-primary-dark btn-primary-dark-alt">
						Descoperă
					</router-link>
					<router-link v-if="showIqos24Plus && !showIqos3Duo" to="/diferente-iqos24plus" class="btn btn-primary-dark btn-primary-dark-alt">
						Descoperă
					</router-link>
				</div>
			</div>
		</div>
		<div class="h-80 w-full flex">
			<!-- spacer -->
		</div>

	</div>
	</template>
	<script>
	import config from "@/config";
	import { mapActions } from "vuex";
	export default {
		name: "Banner",
		props: ['device', 'banner', 'index'],
		components: {
		},
		data() {
			return {
				config,
				isIluma: false,
				showIluma: false,
				showIlumaPrime: false,
				showIqos3Duo: false,
				showIqos24Plus: false,
				showBanner: false,
				isMobile: (window.innerWidth < 768),
			}
		},
		computed: {
			banners() {
				return this.config.banners.filter(banner => !banner.isWide);
			},
			bannersWide() {
				return this.config.banners.filter(banner => banner.isWide);
			}
		},
		methods: {
			deviceDetect() {
				if (this.device.isIluma) {
					this.isIluma = true;
				} else {
					this.isIluma = false;
				}
			},
			detectIlumaPrime() {
				if (this.device.name === 'iqos-iluma-prime') {
					this.showIlumaPrime = true;
				} else {
					this.showIlumaPrime = false;
				}
			},
			detectIqos3Duo() {
				if (this.device.name === 'iqos-3-duo') {
					this.showIqos3Duo = true;
				} else {
					this.showIqos3Duo = false;
				}
			},
			detectIqos24Plus() {
				if (this.device.name === 'iqos-2-4-plus') {
					this.showIqos24Plus = true;
				} else {
					this.showIqos24Plus = false;
				}
			},
			showBannerWide() {
				if (this.device.showBannerWide === true) {
					this.showBanner = true;
				} else {
					this.showBanner = false;
				}
			},


		},
		mounted() {
			this.deviceDetect(),
			this.detectIlumaPrime(),
			this.detectIqos3Duo(),
			this.detectIqos24Plus(),
			this.showBannerWide()
		}
	};
	</script>