<template>
    <div class="container-custom">
        <div class="ip-step ip-step--intro d-flex text-center bg-turquoise-10">
            <div class="align-self-center flex items-center w-full intro">
                <div class="container-custom-desk max-w-sm sm:px-0 px-10">
                    <figure class="mb-5">
                        <img class="mx-auto object-contain items-center" alt="Iqos Pocket" src="@/assets/logo-redesign.svg" />
                    </figure>
                    <p class="text-center p-12 font-bold text-28 pt-45 pb-25">
                        Ai propriul ghid digital chiar la tine în buzunar.
                    </p>
                    <p class="text-18 px-30 pb-64">
                        Află sau reamintește-ți cum funcționează dispozitivul
                        tău IQOS, urmărind video-urile cu instrucțiuni dedicate.
                    </p>
                    <p v-if="!isMobile1" class="uppercase text-center text-18 pb-80">
                        Pentru o experiență optimă, accesează IQOS POCKET de pe
                        telefonul tău.
                    </p>

                    <button
                        @click="goToHow"
                        class="btn btn-primary-dark btn--lg"
                        type="button"
                    >
                        Mai departe
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";

export default {
    name: "Intro",
    data: function () {
        return {
            isMobile1: isMobile,
        };
    },
    methods: {
        goToHow() {
            dataLayer.push({
                'event': 'gtmZoneEvent', 
                'eventCategory': 'iqos_pocket', 
                'eventAction': 'click_mai departe', 
                'eventLabel': 'internal_link_click | from first page to: tutorial step 1'
            })
            this.$router.push({ name: "how-to-use" });
        },
    },
    mounted() {
        this.$store.commit('setProp', { prop: 'showAppHeader', value: false });
    },
};
</script>
