import Vue from 'vue'
import Router from 'vue-router'
import Intro from './views/Intro.vue'
import HowToUse from './views/HowToUse.vue'
import ChooseDevice from './views/ChooseDevice.vue'
import Device from './views/Device.vue'
import FaqCategory from './views/FaqCategory.vue'
import config from './config'
import redirectList from '../redirects'

Vue.use(Router)
// var routes2 = redirectList.map(item => ({
// 	path: item.from,
// 	beforeEnter: () => {
// 		window.location.href = item.to;
// 	},
	
// }));
// console.log(routes2);
var routes = [
	{
		path: '/alege-dispozitiv/iqos-iluma',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iluma';
		},
	},
	{
		path: '/diferente',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket';
		},
	},
	{
		path: '/sfaturi',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-iluma-prime',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iluma-prime';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-iluma-one',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iluma-one';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-originals-duo',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-originals-duo';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-originals-one',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-originals-one';
		},
	},
	{
		path: '/alege-dispozitiv/lil-solid',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/lil-solid';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-3-duo',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3-duo';
		},
	},
	{
		path: '/diferente-iqos3duo',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3-duo';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-3-multi',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3-multi';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-2-4-plus',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-2-4-plus';
		},
	},
	{
		path: '/alege-dispozitiv/iqos-3',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3';
		},
	},
	{
		path: '/faq/iqos-iluma',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iluma';
		},
	},
	{
		path: '/faq/iqos-iluma-prime',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iluma-prime';
		},
	},
	{
		path: '/faq/iqos-originals-one',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-originals-one';
		},
	},
	{
		path: '/faq/iqos-originals-duo',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-originals-duo';
		},
	},
	{
		path: '/faq/lil-solid',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/lil-solid';
		},
	},
	{
		path: '/faq/iqos-3-duo',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3-duo';
		},
	},
	{
		path: '/faq/iqos-3-multi',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3-multi';
		},
	},
	{
		path: '/faq/iqos-2-4-plus',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-2-4-plus';
		},
	},
	{
		path: '/faq/iqos-3',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket/iqos-3';
		},
	},
	{
		path: '/faq/despre-iqos',
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket';
		},
	},
	
	{
		path: '/alege-dispozitiv/noul-iqos-2-4-plus', redirect: '/alege-dispozitiv/iqos-2-4-plus'
	},
	{
		path: '/',
		name: 'intro',
		component: Intro,
	},
	{
		path: '/tutorial',
		name: 'how-to-use',
		component: HowToUse,
		beforeEnter: (to, from, next) => {
			window.location.href = 'https://www.iqos.ro/iqos-pocket';
		},
	},
	{
		path: '/alege-dispozitiv',
		name: 'choose-device',
		component: ChooseDevice,
	},
	{
		path: '/alege-dispozitiv/:deviceName',
		component: Device,
		props: true,
		redirect: '/:deviceName'
	},
	{
		path: '/faq',
		name: 'faq',
		redirect: '/faq/iqos-3-duo',
	},
	{
		path: '/faq/:categoryId',
		name: 'faq-device',
		component: FaqCategory
	},
	{
		path: '/:deviceName',
		component: Device,
		props: true,
	},
];
// var totalRoutes = routes2.concat(routes);
// console.log(totalRoutes);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: function (to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
			}
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
	routes
	// totalRoutes
})