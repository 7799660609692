require('@/lib/TweenMax.min.js');
import '@/scss/app.scss';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

//vue carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

//vue cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

//vue gtag
import VueGtag from "vue-gtag";

import './assets/tailwind.css'
Vue.use(VueGtag, {
  config: { id: "UA-118578350-2" }
});

//import { isMobile } from 'mobile-device-detect';

Vue.config.productionTip = false

window.app = new Vue({
	//mixins: [mixinDetictingMobile],
	router,
	store,
	render: h => h(App),
}).$mount('#app')
