export default [
	{
		categoryTitle: 'IQOS ILUMA',
		categoryId: 'iqos-iluma',
		categoryQuestions: [
			{
				title: 'Ce este IQOS ILUMA?',
				content: `
				<p>
					IQOS ILUMA este cea mai nouă generație de dispozitive IQOS pentru o experiență desăvârșită a tutunului încălzit, fără ardere.
				</p>`
			},
			{
				title: 'Cu ce este diferit IQOS ILUMA față de IQOS ORIGINALS?',
				content: `
				<p>
					Pe lângă diferențele de design, cea mai mare diferență dintre IQOS ORIGINALS și IQOS ILUMA este încălzirea tutunul. IQOS ILUMA face asta prin inducție, deci nu are lamelă și se folosește cu rezervele special create - TEREA. 
				</p>`
			},
			{
				title: 'Ce este tehnologia SMARTCORE INDUCTION SYSTEM™?',
				content: `
				<p>
					Dispozitivele din gama IQOS ILUMA folosesc tehnologia inovatoare fără lamelă SMARTCORE INDUCTION SYSTEM™. Cu ajutorul ei, încălzesc tutunul din interior prin inducție. Astfel, nu mai există riscul ruperii lamelei, dispozitivele nu trebuie curățate niciodată și se degajă mult mai puțin miros comparativ cu generațiile anterioare de IQOS, potrivit majorității consumatorilor (pe baza cercetărilor de piață).
				</p>`
			},
			{
				title: 'Cum pornesc dispozitivul IQOS ILUMA?',
				content: `
				<p>
				Înainte de prima utilizare, trebuie să încarci complet dispozitivul cu ajutorul adaptorului de alimentare și al cablului de încărcare. Procesul durează cam 135 min.
				</p>
				<br>
				<p>Apoi, urmezi pașii de pornire:</p>
				<ul>
					<li>
						<ul>
							<li>Apasă și ține apăsat butonul încărcătorului portabil timp de 4 secunde, apoi eliberează-l; ledurile de stare ale încărcătorului portabil și ale stiloului se vor aprinde încet.</li>
							<li>Introdu stiloul în încărcătorul portabil pentru a începe încărcarea acestuia. Cele două leduri de pe stilou vor arăta nivelul de baterie al acestuia.</li>
						</ul>
					</li>
				</ul>
				`
			},
			{
				title: 'Cum utilizez IQOS ILUMA?',
				content: `
				<p>
					Introdu rezerva TEREA până la linia de pe filtru până când stiloul vibrează, iar ledul/ledurile pulsează. Încălzirea începe automat sau (dacă este necesar) prin apăsarea butonului timp de 1 secundă. 
				</p>
				<ul>
					<li>
						<ul>
							<li>
								ÎNCEPEREA UTILIZĂRII: Începe să utilizezi odată ce stiloul vibrează și luminile se opresc din pulsat.
							</li>
							<li>
								UTILIZARE APROAPE FINALIZATĂ: Pentru a semnaliza ultimele 30 de secunde sau ultimele 2 inhalări ale experienței, stiloul va vibra de două ori, iar ledurile vor pulsa cu o lumină albă.
							</li>
							<li>
								EXTRAGE rezerva TEREA folosită și arunc-o imediat ce s-a răcit complet, în conformitate cu reglementările locale privind eliminarea deșeurilor.
							</li>
							<li>
								REÎNCARCĂ STILOUL: Introdu stiloul în încărcător pentru reîncărcare.
							</li>
						</ul>
					</li>
				</ul>
				<p><br>
				IMPORTANT:<br>		
				Nu ingerați și nu dezasamblați rezervele TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE). Rezervele TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE) conțin componente de mici dimensiuni, cu muchii tăioase.<br>
				Nu lăsați niciodată IQOS ILUMA și IQOS ILUMA PRIME și TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE) la îndemâna copiilor și în apropierea animalelor de companie.<br>
				Nu utilizați niciodată IQOS ILUMA cu țigări, produse similare sau alte rezerve din tutun care nu arde (HEETS/FIIT).  Aceasta poate provoca defecțiuni dispozitivului. Nu modificați rezervele TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE) în niciun fel și nu adăugați nicio substanță în acestea. Această acțiune poate provoca vătămări. <br>
				Pentru detalii suplimentare, consultați secțiunea Important pe <a href="https://www.iqos.ro" target="_blank">iqos.ro</a>.   

				</p>
				`
			},
			{
				title: 'Ce înseamnă indicatorii luminoși?',
				content: `
				<p>
					LUMINĂ GALBENĂ/PORTOCALIE INTERMITENTĂ PE STILOU: înseamnă că bateria este descărcată, iar stiloul trebuie să fie încărcat. <br>
					LUMINĂ ROȘIE INTERMITENTĂ PE STILOU: introdu stiloul în încărcătorul portabil și apoi resetați dispozitivul.<br>		
					LEDURILE DE PE STILOU PULSEAZĂ DE DOUĂ ORI ÎN ALB: înseamnă că IQOS ILUMA se află în afara intervalului de temperatură de funcționare. <br>
					Intervalul de temperatură de funcționare este cuprins între >0°C/<40°C.
				</p>
				`
			},
			{
				title: 'Ce înseamnă vibrațiile de la IQOS ILUMA?',
				content: `
				<p>
					Dacă ai avut un IQOS 3 DUO, îți va fi foarte ușor să interpretezi vibrațiile IQOS ILUMA, pentru că sunt similare:
					<ul>
						<li>
							<ul>
								<li>
									o vibrație: La începutul încălzirii 
								</li>
								<li>
									o vibrație: Când dispozitivul este gata de utilizare
								</li>
								<li>
									o vibrație: Când sesiunea de utilizare este aproape de final (30 de secunde înainte de final)
								</li>
							</ul>
						</li>
					</ul>
					Dacă dispozitivul nu vibrează, deși stiloul este încărcat, acest lucru poate indica o funcționare defectuoasă a stiloului IQOS ILUMA. În plus, poate însemna că tu nu ai perceput vibrația sau funcția de vibrație a fost dezactivată.
				</p>
				`
			},
			{
				title: 'Ce este sistemul de încălzire cu inducție și de ce este mai bun?',
				content: `
				<p>
					Revoluționarul sistem cu inducție SMARTCORE INDUCTION SYSTEM™ din IQOS ILUMA și  TEREA reinventează complet modul în care este încălzit tutunul. <br>
					Noul sistem fără lamelă de încălzire SMARTCORE INDUCTION SYSTEM™ utilizează un element inovator de încălzire din metal, situat în interiorul rezervei și placat cu oțel inoxidabil pentru o încălzire desăvârșită a tutunului. <br>
					Astfel, tutunul este încălzit dinspre interior, oferind un gust real de tutun și o satisfacție pe măsură, fără riscul de rupere a lamelei și mai puțin miros comparativ cu generațiile anterioare de IQOS (potrivit majorității consumatorilor în cadrul cercetărilor de piață). IQOS ILUMA nu necesită curățare, este ușor de utilizat și oferă o gamă largă de consumabile din tutun.
				</p>
				`
			},
			{
				title: 'Cum verific câte utilizări mai am?',
				content: `
				<p>
					Poți face asta în două moduri: fie pe stilou, fie pe încărcătorul portabil
					<br><br>
					Verificarea stării pe stilou: înclină stiloul spre tine sau apasă scurt butonul. Ledurile vor indica nivelul bateriei. 2 leduri înseamnă că este pregătit pentru 2 utilizări, iar 1 led înseamnă că este pregătit pentru 1 utilizare.
					<br><br>
					Verificarea stării pe încărcătorul portabil: apasă scurt butonul încărcătorului portabil.<br>
					Ledurile de pe încărcătorului portabil se vor aprinde astfel: 2 leduri înseamnă că este pregătit pentru 2 utilizări, 1 led înseamnă că este pregătit pentru 1 utilizare.
				</p>
				`
			},
			{
				title: 'Care este diferența dintre IQOS ILUMA și IQOS ILUMA PRIME?',
				content: `
				<p>
					ILUMA PRIME este dispozitivul premium din gamă. Are un design și materiale inovatoare și nu vine ca un upgrade al unui dispozitiv deja existen. IQOS ILUMA are un design similar cu IQOS 3 DUO, însă are o tehnologie diferită: SMARTCORE INDUCTION SYSTEM™. Ambele dispozitive au stilouri, ambele sunt la fel de compacte și ușoare, însă sunt diferite prin design, accesorii compatibile și materiale folosite.
				</p>
				`
			},
			{
				title: 'Ce adaptori și cabluri de încărcare de la dispozitivele anterioare pot folosi pentru IQOS ILUMA?',
				content: `
				<p>
					Doar cablurile de încărcare IQOS ORIGINALS DUO, IQOS ORIGINALS ONE si IQOS 3 (IQOS 3 DUO și IQOS 3 MULTI) pot fi utilizate cu IQOS ILUMA, deoarece dispozitivul este prevăzut cu port USB de tip C. Cablurile IQOS 2.4 /2.4 PLUSTM nu sunt compatibile, deoarece sunt de tip USB - B.
				</p>
				`
			},
			{
				title: 'Cum resetez dispozitivul IQOS ILUMA?',
				content: `
				<p>
					Introdu stiloul în încărcătorul portabil și apasă butonul timp de 10 secunde, apoi eliberează-l. Toate ledurile se vor stinge, vor pulsa de două ori, iar apoi se vor aprinde pentru a confirma resetarea.
				</p>
				`
			},
			{
				title: 'IQOS ILUMA poate fi folosit cu rezervele din tutun HEETS sau Fiit?',
				content: `
				<p>
					Nu. Pentru că are o tehnologie diferită față de restul dispozitivelor IQOS și încălzește tutunul prin inducție, IQOS ILUMA trebuie folosit exclusiv cu rezervele din tutun TEREA. Acestea vin într-o gamă largă de sortimente, inspirate de HEETS, deci cu siguranță vei găsi un corespondent. 
					<br><br>
					Important:<br>
					IQOS ILUMA este conceput pentru a fi folosit exclusiv cu rezervele TEREA.<br>
					Rezervele TEREA™️ nu trebuie utilizate cu versiunile IQOS din generațiile anterioare din cauza riscului de deterioare a lamelei.<br>
					Acestea conțin un element metalic ascuțit. Nu lăsați la îndemâna copiilor, pentru a evita dezasamblarea sau înghițirea.
				</p>
				`
			},
			{
				title: 'Este adevărat că IQOS ILUMA nu se curăță niciodată?',
				content: `
				<p>
					Da. Datorită tehnologiei SMARTCORE INDUCTION SYSTEM™ și a faptului că nu are lamelă, IQOS ILUMA nu trebuie curățat niciodată. Dispozitivele din gama IQOS ILUMA sunt cele mai rezistente dispozitive IQOS de până acum și îți oferă o experiență confortabilă – același gust autentic, însă fără curățare și riscul ruperii lamelei.
				</p>
				`
			},
		]
	},
	{
		categoryTitle: 'IQOS ILUMA PRIME',
		categoryId: 'iqos-iluma-prime',
		categoryQuestions: [
			{
				title: 'Ce este IQOS ILUMA PRIME?',
				content: `
				<p>
					IQOS ILUMA PRIME este dispozitivul premium din gama IQOS ILUMA. Vine cu o tehnologie total nouă - SMARTCORE INDUCTION SYSTEM™ - datorită căreia dispozitivul este foarte rezistent. Nu are lamelă, nu trebuie curățat niciodată și oferă o experiență IQOS revoluționară. Are un design remarcabil, intuitiv, inteligent și este cel mai performant dispozitiv IQOS de până acum.
				</p>
				`
			},
			{
				title: 'Ce este nou la designul dispozitivului IQOS ILUMA PRIME?',
				content: `
				<p>
					IQOS ILUMA PRIME este un dispozitiv unic, premium, cu design inovator și materiale de cea mai înaltă calitate, precum și posibilități de personalizare și caracteristici avansate. Stiloul IQOS ILUMA PRIME și încărcătorul portabil sunt disponibile într-un corp din aluminiu, cu o carcasă din poliuretan pe încărcătorul portabil.
				</p>
				`
			},
			{
				title: 'Care este diferența dintre IQOS ILUMA și IQOS ILUMA PRIME?',
				content: `
				<p>
					ILUMA PRIME este dispozitivul premium din gamă. Are un design și materiale inovatoare și nu vine ca un upgrade al unui dispozitiv deja existent. IQOS ILUMA are un design similar cu IQOS 3 DUO, însă are o tehnologie diferită: SMARTCORE INDUCTION SYSTEM™. Ambele dispozitive au stilouri, ambele sunt la fel de compacte și ușoare, însă sunt diferite prin design, accesorii compatibile și materiale folosite.
				</p>
				`
			},
			{
				title: 'IQOS ILUMA PRIME poate fi folosit cu rezervele din tutun HEETS sau Fiit?',
				content: `
				<p>
					Nu. Pentru că are o tehnologie diferită față de restul dispozitivelor IQOS și încălzește tutunul prin inducție, IQOS ILUMA PRIME trebuie folosit exclusiv cu rezervele din tutun TEREA. Acestea vin într-o gamă largă de sortimente, inspirate de HEETS, deci cu siguranță vei găsi un corespondent. 
					<br><br>
					Important:<br>
					IQOS ILUMA este conceput pentru a fi folosit exclusiv cu rezervele TEREA.<br>
					Rezervele TEREA™️ nu trebuie utilizate cu versiunile IQOS din generațiile anterioare din cauza riscului de deterioare a lamelei.<br>
					Acestea conțin un element metalic ascuțit. Nu lăsați la îndemâna copiilor, pentru a evita dezasamblarea sau înghițirea.
				</p>
				`
			},
			{
				title: 'Cum pot să schimb husa și inelul de la IQOS ILUMA PRIME?',
				content: `
				<p>
					Ca să schimbi husa IQOS ILUMA PRIME: 
					<ul>
						<li>
							<ul>
								<li>Deschide complet carcasa de poliuretan și scoate-o de sus în jos, apoi scoate-o de pe încărcătorul portabil.</li>
								<li>Atașează carcasa de poliuretan prin introducerea magneților liniari de pe husă în zona de aceeași formă de pe încărcătorul portabil.</li>
							</ul>
						</li>
					</ul>
					Ca să schimbi inelul de aluminiu IQOS ILUMA PRIME:
					<ul>
						<li>
							<ul>
								<li>Funcționează numai cu instrumentul de îndepărtare a inelului (instrumentul este un accesoriu care se comercializează separat) </li>
								<li>Așază instrumentul de îndepărtare a inelului deasupra inelului de fixare și rotește-l în sensul invers acelor de ceasornic pentru a debloca inelul.</li>
								<li>Așază noul inel de aluminiu pe stilou cu ajutorul instrumentului de îndepărtare a inelului și rotește-l în sensul acelor de ceasornic pentru a-l bloca în poziție.</li>
							</ul>
						</li>
					</ul>
				</p>
				`
			},
			{
				title: 'Pot folosi încărcătorul portabil de la IQOS 3 DUO cu IQOS ILUMA PRIME?',
				content: `
				<p>
					Nu. Poți utiliza încărcătorul portabil IQOS 3 DUO pentru a-ți încărca stiloul IQOS ILUMA, însă acesta nu va funcționa cu stiloul IQOS ILUMA PRIME.
				</p>
				`
			},
			{
				title: 'Cu ce este diferit IQOS ILUMA PRIME față de IQOS ORIGINALS?',
				content: `
				<p>
					Pe lângă diferențele de design, cea mai mare diferență dintre IQOS ORIGINALS și IQOS ILUMA PRIME este încălzirea tutunului. IQOS ILUMA PRIME face asta prin inducție, deci nu are lamelă și se folosește cu rezervele special create – TEREA. 
				</p>
				`
			},
			{
				title: 'Ce este tehnologia SMARTCORE INDUCTION SYSTEM™?',
				content: `
				<p>
					Dispozitivele din gama IQOS ILUMA folosesc tehnologia inovatoare fără lamelă SMARTCORE INDUCTION SYSTEM™. Cu ajutorul ei, încălzesc tutunul din interior prin inducție. Astfel, nu mai există riscul ruperii lamelei, dispozitivele nu trebuie curățate niciodată și se degajă mult mai puțin miros comparativ cu generațiile anterioare de IQOS, potrivit majorității consumatorilor (pe baza cercetărilor de piață). 
				</p>
				`
			},
			{
				title: 'Cum pornesc dispozitivul IQOS ILUMA PRIME?',
				content: `
				<p>
					Înainte de prima utilizare, trebuie să încarci complet dispozitivul cu ajutorul adaptorului de alimentare și al cablului de încărcare. Procesul durează cam 135 min.
					<br><br>
					Apoi, urmezi pașii de pornire:
					<ul>
						<li>
							<ul>
								<li>Apasă și ține apăsat butonul încărcătorului portabil timp de 4 secunde, apoi eliberează-l; ledurile de stare ale încărcătorului portabil și ale stiloului se vor aprinde încet.</li>
								<li>Introdu stiloul în încărcătorul portabil pentru a începe încărcarea acestuia și închide carcasa. Cele două leduri de pe stilou vor arăta nivelul de baterie al acestuia.</li>
							</ul>
						</li>
					</ul>
				
				</p>
				`
			},
			{
				title: 'Cum utilizez IQOS ILUMA PRIME?',
				content: `
				<p>
					Introdu rezerva TEREA până la linia de pe filtru până când stiloul vibrează, iar ledul/ledurile pulsează. Încălzirea începe automat sau (dacă este necesar) prin apăsarea butonului timp de 1 secundă. 
					<ul>
						<li>
							<ul>
								<li>ÎNCEPEREA UTILIZĂRII: Începe să utilizezi odată ce stiloul vibrează și luminile se opresc din pulsat.</li>
								<li>UTILIZARE APROAPE FINALIZATĂ: Pentru a semnaliza ultimele 30 de secunde sau ultimele 2 inhalări ale experienței, stiloul va vibra de două ori, iar ledurile vor pulsa cu o lumină albă.</li>
								<li>EXTRAGE rezerva TEREA folosită și arunc-o imediat ce s-a răcit complet, în conformitate cu reglementările locale privind eliminarea deșeurilor.</li>
								<li>REÎNCARCĂ STILOUL: Introdu stiloul în încărcător pentru reîncărcare.</li>
							</ul>
						</li>
					</ul>
					IMPORTANT:<br>
					Nu ingerați și nu dezasamblați rezervele TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE). Rezervele TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE) conțin componente de mici dimensiuni, cu muchii tăioase.<br>
					Nu lăsați niciodată IQOS ILUMA și IQOS ILUMA PRIME și TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE) la îndemâna copiilor și în apropierea animalelor de companie.<br>
					Nu utilizați niciodată IQOS ILUMA cu țigări, produse similare sau alte rezerve din tutun care nu arde (HEETS/FIIT).  Aceasta poate provoca defecțiuni dispozitivului. Nu modificați rezervele TEREA (SMARTCORE STICKS DIN TUTUN CARE NU ARDE) în niciun fel și nu adăugați nicio substanță în acestea. Această acțiune poate provoca vătămări. <br>
					Pentru detalii suplimentare, consultați secțiunea Important pe <a href="https://www.iqos.ro" target="_blank">iqos.ro</a>.   
				</p>
				`
			},
			{
				title: 'Ce înseamnă indicatorii luminoși?',
				content: `
					<p>
						LUMINĂ GALBENĂ/PORTOCALIE INTERMITENTĂ PE STILOU: înseamnă că bateria este descărcată, iar stiloul trebuie să fie încărcat. <br>
						LUMINĂ ROȘIE INTERMITENTĂ PE STILOU: introdu stiloul în încărcătorul portabil și apoi resetați dispozitivul.<br>
						LEDURILE DE PE STILOU PULSEAZĂ DE DOUĂ ORI ÎN ALB: înseamnă că IQOS ILUMA se află în afara intervalului de temperatură de funcționare. <br>
						Intervalul de temperatură de funcționare este cuprins între >0°C/<40°C.
					</p>
				`,
			},
			{
				title: 'Ce înseamnă vibrațiile de la IQOS ILUMA PRIME?',
				content: `
					<p>
						Dacă ai avut un IQOS 3 DUO, îți va fi foarte ușor să interpretezi vibrațiile IQOS ILUMA PRIME, pentru că sunt similare:
						<ul>
							<li>
								<ul>
									<li>o vibrație: La începutul încălzirii</li>
									<li>o vibrație: Când dispozitivul este gata de utilizare</li>
									<li>o vibrație: Când sesiunea de utilizare este aproape de final (30 de secunde înainte de final)</li>
								</ul>
							</li>
						</ul>
						Dacă dispozitivul nu vibrează, deși stiloul este încărcat, acest lucru poate indica o funcționare defectuoasă a stiloului IQOS ILUMA PRIME. În plus, poate însemna că tu nu ai perceput vibrația sau funcția de vibrație a fost dezactivată.
					</p>
				`,
			},
			{
				title: 'Ce este sistemul de încălzire cu inducție și de ce este mai bun?',
				content: `
					<p>
						Revoluționarul sistem cu inducție SMARTCORE INDUCTION SYSTEM™ din IQOS ILUMA PRIME și TEREA reinventează complet modul în care este încălzit tutunul. <br>
						Noul sistem fără lamelă de încălzire SMARTCORE INDUCTION SYSTEM™ utilizează un element inovator de încălzire din metal, situat în interiorul rezervei și placat cu oțel inoxidabil pentru o încălzire desăvârșită a tutunului. <br>
						Astfel, tutunul este încălzit dinspre interior, oferind un gust real de tutun și o satisfacție pe măsură, fără riscul de rupere a lamelei și mai puțin miros comparativ cu generațiile anterioare de IQOS (potrivit majorității consumatorilor în cadrul cercetărilor de piață). IQOS ILUMA PRIME este cel mai bun dispozitiv IQOS de până acum, nu necesită curățare, este ușor de utilizat și oferă o gamă largă de consumabile din tutun.
					</p>
				`,
			},
			{
				title: 'Cum verific câte utilizări mai am?',
				content: `
					<p>
						Poți face asta în două moduri: fie pe stilou, fie pe încărcătorul portabil.
						<br><br>
						Verificarea stării pe stilou: înclină stiloul spre tine sau apasă scurt butonul. Ledurile vor indica nivelul bateriei. 2 leduri înseamnă că este pregătit pentru 2 utilizări, iar 1 led înseamnă că este pregătit pentru 1 utilizare.
						<br><br>	
						Verificarea stării pe încărcătorul portabil: apasă scurt butonul încărcătorului portabil.<br>
						Ledurile de pe încărcătorului portabil se vor aprinde astfel: 2 leduri înseamnă că este pregătit pentru 2 utilizări, 1 led înseamnă că este pregătit pentru 1 utilizare.
					</p>
				`,
			},
			{
				title: 'Îmi pot personaliza dispozitivul IQOS ILUMA PRIME?',
				content: `
					<p>
						Da. Dispozitivul IQOS ILUMA PRIME poate fi personalizat prin folosirea inelelor și carcaselor din poliuretan. Pentru acest dispozitiv există o gamă variată de accesorii, deci alege ce ți se potrivește pentru a-l face să arate așa cum îți dorești.
					</p>
				`,
			},
		]
	},
	{
		categoryTitle: 'IQOS ORIGINALS ONE',
		categoryId: 'iqos-originals-one',
		categoryQuestions: [
			{
				title: 'Ce este IQOS ORIGINALS ONE?',
				content: `
				<p>
					IQOS ORIGINALS ONE este un dispozitiv all-in-one, intuitiv, ce oferă gustul și satisfacția reală a tutunului în până la 20 de utilizări la o încărcare completă, inclusiv 2 utilizări consecutive.
				</p>
				<ul>
					<li>
						Dispozitivul vine într-un format compact all-in-one și se bazează pe tehnologia HEATCONTROL, reușind astfel să încălzească tutunul în mod optim pentru un gust și satisfacție adevărată de tutun. Pentru că are un aspect simplu și intuitiv, experiența ta de folosire va fi optimă. Cu ajutorul butonului poți contoriza sesiunea, poți afla câte utilizări ți-au mai rămas, dar și nivelul de încărcare a bateriei. 
					</li>
				</ul>
				`,
			},
			{
				title: 'Cum se pornește dispozitivul?',
				content: `
					<p>
						Dacă îl folosești pentru prima dată, ai de urmat 3 pași simpli:
					</p>
					<ul>
						<li>
							<ul>
								<li>Înainte de prima utilizare, încărcă în mod complet dispozitivul IQOS ORIGINALS ONE cu adaptorul și cabul (aproximativ 90 min.)</li>
								<li>Pentru a începe, apasă și ține apăsat butonul de pornire din mijlocul dispozitivului timp de 4 secunde, apoi eliberează-l</li>
								<li>Ledurile de stare se vor aprinde încet</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum se încălzește IQOS ORIGINALS ONE?',
				content: `
					<ul>
						<li>
							<ul>
								<li>Deschide dispozitivul prin glisarea ușiței din capăt</li>
								<li>Introdu rezerva HEETS până la nivelul liniei de pe filtrum cu filtrul în afara dispozitivului</li>
								<li>Pornește încălzirea prin apăsarea butonului până când dispozitivul IQOS ORIGINALS ONE vibrează, iar led-urile de stare pulsează</li>
								<li>După ce acesta vibrează și led-ul (led-urile) de stare rămân aprinse, poți utiliza dispozitivul</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum pot verifica dacă dispozitivul e încărcat?',
				content: `
					<p>
						Pentru a verifica nivelul de încărcare a dispozitivului IQOS ORIGINALS ONE, apasă și eliberează butonul de pe dispozitiv pentru mai puțin de 1 secundă (apăsare scurtă).
					</p>
					<ul>
						<li>
							Numărul de led-uri aprinse corespund aproximativ cu nivelul de baterie a dispozitivului:
						</li>
						<li>
							<ul>
								<li>4 led-uri = Încărcat mai mult de 75%</li>
								<li>1 led = Încărcare scăzută. Necesită încărcare</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Cât timp durează încărcarea completă?',
				content: `
					<p>
						Durează mai puțin de 90 de minute ca dispozitivul IQOS ORIGINALS ONE să se încarce de la o priză de perete atunci când folosești adaptorul AC IQOS. Dispozitivul IQOS ORIGINALS ONE nu necesită o încărcare completă pentru a funcționa, totuși îți recomandăm să-l încarci complet înainte de prima utilizare.
					</p>
					<ul>
						<li>
							Pentru o utilizare în siguranță, încărcă dispozitivul utilizând adaptorul de alimentare AC IQOS și cablul USB A-C sau accesoriile originale de încărcare IQOS.
						</li>
						<li>
							Timpul de încărcare poate depinde de condițiile de utilizare, de ciclul de viață al bateriei, de condițiile meteorologice și de utilizarea dispozitivului.
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum curăț dispozitivul?',
				content: `
					<ul>
						<li>
							<ul>
								<li>
									Întâi de toate, desfă capacul dispozitivului atunci când acesta s-a răcit. Deschide prin glisare instrumentul de curățare IQOS și verifică să nu fie deformat și să nu aibă particule.
								</li>
								<li>
									Apoi, deschide partea superioară a instrumentului de curățare cu cap dublu (capacul cu curelușă) și introdu peria în camera de încălzire fără a o răsuci, până când atinge partea inferioară 
								</li>
								<li>
									Rotește de 3 ori înainte de a extrage periuța și apoi extrage fără a răsuci și lovește ușor pentru a elimina orice reziduu liber care rămâne în camera de încălzire
								</li>
							</ul>
						</li>
						<li>
							Urmează curățarea lamelei: 
						</li>
						<li>
							<ul>
								<li>
									Schimbă capetele instrumentului de curățare, deschizând partea de jos pentru a utiliza dispozitivul special de curățare a lamelei
								</li>
								<li>
									Ai grijă să aliniezi instrumentul de curățare cu tubul înainte de a-l introduce
								</li>
								<li>
									Prin trei mișcări ajută cele două perii să curețe ușor lamela, deoarece acestea pivotează în apropierea lamelei
								</li>
								<li>
									Atinge din nou ușor dispozitivul pentru a îndepărta orice reziduu liber
								</li>
								<li>
									Folosește bețișoarele curățare IQOS pentru finisare.
								</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce înseamnă vibrațiile dispozitivului?',
				content: `
					<p>
						La începutul utilizării:
					</p>
					<ul>
						<li>
							<ul>
								<li>
									Când apeși butonul pentru a începe să încălzești IQOS ORIGINALS ONE, acesta va vibra în timp ce luminile de stare pulsează.
								</li>
								<li>
									Atunci când dispozitivul este gata de utilizare, acesta va vibra, iar luminile de stare vor rămâne fixe.
								</li>
							</ul>
						</li>
						<li>
							Aproape de final:
								<ul>
									<li>
										Dispozitivul va vibra o dată iar un led va pulsa în alb pentru a indica ultimele 30 de secunde sau ultimele 2 inhalări dintr-o rezervă de tutun.
									</li>
								</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum pot afla cât mai am rămas din experiența de utilizare?',
				content: `
					<p>
						Poți folosi butonul de pe IQOS ORIGINALS ONE pentru a urmări cât mai ai din experiență până la terminarea rezervei de tutun. Asta pentru că în timpul utilizării, numărul de LED-uri aprinse din jurul butonului arată cât mai ai din experiența IQOS curentă:
					</p>
					<ul>
						<li>
							<ul>
								<li>La începutul unei experiențe se vor ilumina 4 secțiuni complete;</li>
								<li>Când se aprind 3 secțiuni: 75% din experiență rămâne; </li>
								<li>Când se aprind 2 secțiuni, rămâne aproximativ jumătate din experiență</li>
								<li>Când se aprinde 1 secțiune (înainte de alerta de vibrație), experiența este aproape terminată</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum pot reseta dispozitivul?',
				content: `
					<p>
						Pentru a reseta dispozitivul IQOS ORIGINALS ONE apasă butonul dispozitivului timp de 10 secunde apoi eliberează. Led-urile de stare se vor opri și se vor reaprinde pentru a confirma resetarea.
					</p>
				`,
			},
			{
				title: 'Care este diferența între IQOS ORIGINALS DUO și IQOS ORIGINALS ONE?',
				content: `
					<p>
						Datorită tehnologiei brevetate HEATCONTROL, ambele dispozitive oferă gustul și satisfacția tutunului adevărat. Principala diferență este formatul: IQOS ORIGINALS DUO vine în două piese și include un stilou și un încărcător, iar IQOS ORIGINALS ONE este un format dintr-o singură piesă. 
					</p>
					<ul>
						<li>
							Ambele dispozitive oferă 20 de utilizări la o încărcare completă, cu diferența că IQOS ORIGINALS DUO oferă 2 utilizări consecutive, iar IQOS ORIGINALS ONE oferă 3 utilizări consecutive. 
						</li>
						<li>
							Ambele dispozitive pot fi personalizate cu accesorii, în timp ce IQOS ORIGINALS DUO oferă o gamă îmbunătățită de ușițe și clapete colorate.
						</li>
					</ul>
				`,
			},
		]
	},
	{
		categoryTitle: 'IQOS ORIGINALS DUO',
		categoryId: 'iqos-originals-duo',
		categoryQuestions: [
			{
				title: 'Cu ce este IQOS ORIGINALS DUO diferit față de IQOS 3 DUO?',
				content: `
					<p>
						IQOS 3 DUO va fi înlocuit de IQOS ORIGINALS DUO. Deși noul dispozitiv oferă tot două utilizări consecutive, fără încărcare, IQOS Original DUO vine în plus cu cel mai compact și ușor stilou de până acum și 4 culori vibrante din care poți alege.
					</p>
				`,
			},
			{
				title: 'Cum îl utilizez pentru prima dată?',
				content: `
					<ul>
						<li>Când scoți dispozitivul din cutie, apasă și ține apăsat butonul încărcătorului portabil IQOS ORIGINALS DUO timp de 4 secunde, apoi eliberează; LED-urile care indică statusul bateriei și LED-ul de status al înărcătorului portabil se vor aprinde treptat. </li>
						<li>Dacă nu eliberezi butonul în acest timp, dispozitivul se va reseta.</li>
						<li>Pentru a încărca stiloul, introdu-l în încărcătorul portabil apoi închide capacul pentru a se încărca. Indicatorul de stare de pe stiloul va arăta nivelul de încărcare a stiloului. Un stilou complet încărcat va fi indicat prin 2 led-uri albe complet aprinse. Cele 2 LED-uri albe aprinse de pe stilou înseamnă că este pregătit pentru utilizare pentru 2 experiențe.  1 LED alb corespunde unei experiențe disponibile.</li>
						<li>Introdu și apasă ușor rezerva de tutun HEETS până la linia de pe filtru, cu filtrul orientat spre exterior.</li>
						<li>Apasă butonul stiloului până când acesta vibrează și LED-ul pulsează în alb. Poți începe utilizarea de îndată ce stiloul vibrează de două ori. Ca să-ți semnalizeze ultimele 30 de secunde sau ultimele două inhalări, IQOS ORIGINALS DUO va vibra de două ori iar LED-urile vor pulsa alb. Extrage rezerva HEETS, glisează capacul în sus și apoi scoate rezerva folosită.</li>
					</ul>
				`,
			},
			{
				title: 'Ce înseamnă indicatorii luminoși?',
				content: `
					<p>
						Orice LED alb indică faptul că dispozitivul IQOS ORIGINALS DUO efectuează diferite operațiuni, cum ar fi încărcare, resetare, e gata de utilizare, încărcare completă sau ieșire în afara plajei de temperaturi a încărcătorului portabil, etc.
					</p>
					<ul>
						<li>
							Orice LED roșu indică o eroare a dispozitivului.
						</li>
						<li>
							LED-uri roșii pe încărcătorul portabil:
							<ul>
								<li>LED-ul stiloului - Roșu intermitent - Funcționare defectuoasă a stiloului, stiloul este descărcat, lamela este ruptă</li>
								<li>Numai LED-ul de jos al încărcătorului - Roșu intermitent-> efectuează resetarea</li>
							</ul>
						</li>
						<li>
							LED-uri roșii pe stilou:
							<ul>
								<li>LED-ul stiloului clipește roșu intermitent – Lamela de încălzire este defectă sau există o defecțiune a stiloului -> efectuează o resetare</li>
							</ul>
						</li>
						<li>
							LED alb intermitent pe stilou:
							<ul>
								<li>IQOS se află în afara plajei de temperaturi de operare (0°C- -50°C) sau stiloul trebuie inserat în încărcătorul portabil cu clapeta închisă până se încarcă complet.
								</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce culori sunt disponibile pentru dispozitivul IQOS ORIGINALS DUO?',
				content: `
					<p>
						IQOS ORIGINALS DUO este disponibil în 4 culori vibrante: Turquoise, Slate, Scarlet și Silver.
					</p>
				`,
			},
			{
				title: 'Cum știu dacă dispozitivul e încărcat?',
				content: `
					<p>
						Pentru a verifica nivelul de baterie al încărcătorului portabil IQOS ORIGINAL DUO, apasă și eliberează butonul încărcătorului portabil. Apoi, led-ul de status al încărcătorului portabil IQOS ORIGINALS DUO se va aprinde timp de 3 secunde pentru a indica nivelul de baterie:
					</p>
					<ul>
						<li>
							<ul>
								<li>4 led-uri = Încărcat mai mult de 75%</li>
								<li>1 led = Încărcare scăzută. Necesită încărcare</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce înseamnă vibrațiile dispozitivului?',
				content: `
					<p>
						Stiloul va vibra:
					</p>
					<ul>
						<li>
							<ul>
								<li>La începutul încălzirii (odată ce apeși butonul pentru a începe încălzirea) - o vibrație</li>
								<li>Când dispozitivul este gata de utilizare - o vibrație</li>
								<li>Când sesiunea este aproape finalizată (cu 30 de secunde înainte de sfârșit) - o vibrație</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Care este diferența între IQOS ORIGINALS DUO și IQOS ORIGINALS ONE?',
				content: `
					<ul>
						<li>
							Datorită tehnologiei brevetate HEATCONTROL, ambele dispozitive oferă gustul și satisfacția tutunului adevărat. Principala diferență este formatul: IQOS ORIGINALS DUO vine în două piese și include un stilou și un încărcător, iar IQOS ORIGINALS ONE este un format dintr-o singură piesă. 
						</li>
						<li>
							Ambele dispozitive oferă 20 de utilizări la o încărcare completă, cu diferența că IQOS ORIGINALS DUO oferă 2 utilizări consecutive, iar IQOS ORIGINALS ONE oferă 3 utilizări consecutive. 
						</li>
						<li>
							Ambele dispozitive pot fi personalizate cu accesorii, în timp ce IQOS ORIGINALS DUO oferă o gamă îmbunătățită de ușițe și clapete colorate.
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum pot reseta dispozitivul?',
				content: `
					<ul>
						<li>
							Pentru a reseta dispozitivul IQOS ORIGINALS DUO apasă butonul încărcătorului portabil timp de 10 secunde (până led-urile se sting), apoi eliberează.
						</li>
						<li>
							Toate led-urile încărcătorului portabil se vor opri, vor lumina de 3 ori, apoi se vor stinge treptat, pentru a confirma resetarea. 
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce este sistemul IQOS ProtectPlus<sup>TM</sup>?',
				content: `
					<p>
						IQOS ProtectPlus<sup>TM</sup> redefinește conceptul de protecție și fiabilitate al dispozitivelor IQOS ORIGINALS și ntroduce o serie de caracteristici îmbunătățite, cum ar fi:
					</p>
					<ul>
						<li>
							<ul>
								<li>carcasa exterioară robustă îmbunătățește rezistența la șocuri a dispozitivului</li>
								<li>noua deschidere a ușiței laterale testată pentru a garanta o performanță fiabilă a dispozitivului</li>
								<li>camera electronică reproiectată a stiloului IQOS ORIGINALS DUO oferă o fiabilitate îmbunătățită a dispozitivului</li>
							</ul>
						</li>
					</ul>
				`,
			},
		]
	},
	{
		categoryTitle: 'lil SOLID 2.0',
		categoryId: 'lil-solid',
		categoryQuestions: [
			{
				title: 'Ce este lil SOLID 2.0?',
				content: `
					<p>
						lil<sup>TM</sup> SOLID 2.0 este un dispozitiv de încălzire a tutunului all-in-one creat de IQOS<sup>TM</sup>, pentru a oferi un gust autentic de tutun, însă fără fum și fără scrum. Se utilizează exclusiv cu rezervele din tutun Fiit și reprezintă o nouă alternativă fără fum la țigări pentru acei adulți care altfel ar continua să fumeze sau să utilizeze alte produse cu nicotină.
					</p>
				`,
			},
			{
				title: 'Cum se utilizează corect lil SOLID 2.0?',
				content: `
					<p>
						Dacă folosești pentru prima dată dispozitivul, mai întâi este necesar să-l activezi din modul de repaus apăsând butonul timp de 2 secunde sau conectând încărcătorul la priză. O încărcare completă va dura aproximativ 2 ore.
					</p>
					<ul>
						<li>
							<ul>
								<li>Apoi, glisează și deschide capacul superior al spațiului circular în care se introduce rezerva din partea de sus a dispozitivului.</li>
								<li>Introdu rezervă din tutun Fiit<sup>TM</sup> cu partea de tutun în jos.</li>
								<li>Apasă pe buton timp de 2 secunde până vibrează. LED-ul de stare se aprinde albastru deschis și dispozitivul vibrează în același timp. Preîncălzirea durează aproximativ 25 de secunde, în funcție de contextul de utilizare. Când procesul de preîncălzire se încheie, LED-ul de stare se aprinde și dispozitivul vibrează în același timp pentru a indica faptul că rezerva de tutun poate fi utilizată.</li>
							</ul>
						</li>
					</ul>
					<p>
						Dispozitivul tău este gata de utilizare!
					</p>
				`,
			},
			{
				title: 'De câte ori consecutiv pot utiliza dispozitivul lil<sup>TM</sup> SOLID 2.0?',
				content: `
					<p>
						Poți face acest lucru de până la 3 ori, fără să fie nevoie de timp de așteptare între 3 rezerve Fiit<sup>TM</sup>. După aceste 3 utilizări consecutive, dispozitivul se răcește automat și va trebui să aștepți până ce temperatura se stabilizează. 
					</p>
					<ul>
						<li>
							Pentru siguranța ta, îți recomandăm să nu utilizezi dispozitivul lil<sup>TM</sup> SOLID 2.0 de mai mult de 3 ori în 18 minute pentru că acesta are nevoie să se răcească. Concret, durează 3 minute și 30 de secunde până să poți utiliza o altă rezervă Fiit<sup>TM</sup>.
						</li>
						<li>
							Ca să începi o sesiune nouă, trebuie să repeți pașii explicați mai sus.
						</li>
					</ul>
				`,
			},
			{
				title: 'Cât durează o experiență?',
				content: `
					<p>
						Durata experienței este de 14 inhalări sau 5 minute, oricare survine prima. Cu 30 de secunde înainte de sfârșitul sesiunii sau când au rămas ultimele 3 inhalări, dispozitivul va vibra o dată, iar LED-ul de stare se aprinde albastru deschis până la finalul sesiunii.
					</p>
					<ul>
						<li>
							Când sesiunea se apropie de final, LED-ul de stare și dispozitivul se opresc automat. Nu trebuie să apeși butonul. Pentru a elimina rezerva de tutun, răsucește rezerva într-o singură direcție de 3 sau mai multe ori.
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce trebuie să fac după utilizarea dispozitivului lil<sup>TM</sup> SOLID 2.0?',
				content: `
					<p>
						După ce ai îndepărtat rezerva Fiit<sup>TM</sup>, ai grijă să nu atingi partea de tutun, pentru că aceasta poate fi fierbinte după utilizare. Apoi, nu uita să închizi capacul dispozitivului ca să eviți pătrunderea particulelor străine.
					</p>
				`,
			},
			{
				title: 'Cum se verifică nivelul de încărcare a dispozitivului?',
				content: `
					<p>
						Pentru a verifica nivelul de încărcare a dispozitivului lil <sup>TM</sup> SOLID 2.0 apasă butonul sau conectează dispozitivul la o priză utilizând cablul USB și adaptorul AC.
					</p>
					<ul>
						<li>
							Nivelul de încărcare a dispozitivului este afișat după cum urmează: albastru (100 ~ 60% încărcat), albastru deschis (60 ~ 30% încărcat), portocaliu (30 ~ 1% încărcat), roșu - (trebuie încărcat).
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum se încarcă dispozitivul?',
				content: `
					<ul>
						<li>
							<ul>
								<li>
									Conectează dispozitivul la cablul USB și introdu adaptorul AC în priză.
								</li>
								<li>
									Durează aproximativ 2 ore pentru ca dispozitivul lil<sup>TM</sup> SOLID 2.0 să fie complet încărcat la o priză electrică dacă utilizezi adaptorul AC furnizat. 
								</li>
								<li>
									Încărcarea este indicată de LED-ul de stare care se aprinde intermitent.
								</li>
								<li>
									Când dispozitivul este complet încărcat, LED-ul se închide. 
								</li>
								<li>
									Dispozitivul lil<sup>TM</sup> SOLID nu trebuie să fie complet încărcat pentru a funcționa, însă recomandăm încărcarea completă a acestuia înainte de prima utilizare.
								</li>
								<li>
									Pentru a-l utiliza în siguranță, încarcă dispozitivul cu ajutorul cablului USB și adaptorului AC special concepute pentru lil<sup>TM</sup> SOLID.  
								</li>
								<li>
									Utilizarea altor tipuri de încărcătoare, precum încărcătoarele de smartphone, cele pentru mașină sau pentru sursele de alimentare portabile pot determina o eficiență redusă de încărcare sau pot reduce durata de viață a bateriei.
								</li>
								<li>
									Pentru a verifica nivelul de încărcare a dispozitivului lil<sup>TM</sup> SOLID, apasă butonul sau conectează dispozitivul la o priză cu ajutorul cablului USB și a adaptorului AC.
								</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce trebuie să fac pentru a-l curăța?',
				content: `
					<p>
						Înainte de toate, oprește dispozitivul înainte de a-l curăța. Apoi, lasă dispozitivul să se răcească după ultima utilizare.
					</p>
					<ul>
						<li>
							<ul>
								<li>
									Pentru a utiliza capătul pentru ștergere, deschide ușor partea marcată cu &#8857;. 
								</li>
								<li>
									Pentru a utiliza periuța, deschide ușor partea marcată cu  <span class="icon icon--cleanup"></span>
								</li>
								<li>
									Îndepărtează căpăcelul dispozitivului. 
								</li>
								<li>
									Introdu capătul pentru ștergere în dispozitiv și curăță dispozitivul prin răsucire. Repetăacest proces cu peria de curățare.
								</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Cum se resetează?',
				content: `
					<p>
						Pentru a reseta dispozitivul lil SOLID 2.0 trebuie să urmezi câțiva pași simpli:
					</p>
					<ul>
						<li>
							<ul>
								<li>
									Apasă pe buton timp de 8 secunde chiar dacă dispozitivul vibrează după 2 secunde.
								</li>
								<li>
									LED-ul de stare se aprinde de 2 ori în roșu, portocaliu, albastru deschis și albastru, în această ordine. 
								</li>
								<li>
									Când dispozitivul este complet resetat, LED-ul de stare se aprinde albastru deschis timp de 1 secundă, iar dispozitivul vibrează de 2 ori simultan. 
								</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Ce variante de Fiit sunt disponibile?',
				content: `
					<ul>
						<li>
							<ul>
								<li>
									Rezervele Fiit<sup>TM</sup> din tutun sunt concepute să fie utilizate exclusiv cu dispozitivul lil<sup>TM</sup> SOLID.
								</li>
								<li>
									În România sunt disponibile două variante: Fiit REGULAR și Fiit MARINE.
								</li>
								<li>
									Fiit REGULAR: Amestec de tutun cu note subtile de nucă (notă aromatică: nucă) 
								</li>
								<li>
									Fiit MARINE: Amestec de tutun cu note răcoritoare de mentol (notă aromatică: mentolat)
								</li>
							</ul>
						</li>
					</ul>
				`,
			},
			{
				title: 'Pot utiliza țigări cu dispozitivul lil SOLID?',
				content: `
					<p>
						Nu. Țigările nu pot fi utilizate cu dispozitivul lil<sup>TM</sup> SOLID din cauza conceptului diferit de producție. Rezervele din tutun Fiit<sup>TM</sup> au fost special create pentru a fi utilizate cu dispozitivul lil<sup>TM</sup> SOLID.
					</p>
				`,
			},
		]
	},
	{
		
		categoryTitle: 'IQOS 3 DUO',
		categoryId: 'iqos-3-duo',
		categoryQuestions: [
			{
				title: 'În ce constă diferența dintre IQOS 3 DUO și dispozitivele anterioare?',
				content: `
					<p>
						Inspirat de utilizatorii noștri, a fost proiectat în jurul nevoii tale de a te bucura de momentul IQOS în stilul tău:
					</p>
					<ul>
						<li>Poate fi folosit de două ori consecutiv fără reîncărcarea dispozitivului</li>
						<li>
							Încărcarea mai rapidă a dispozitivului îți permite să te concentrezi pe savurarea experienței și să nu te mai preocupi de reîncărcare:
							<ul>
								<li>Încărcare mai rapidă cu cel puțin 2min 20sec decât cea a dispozitivului IQOS 2.4+*</li>
								<li>Încărcare mai rapidă cu cel puțin 1min 40sec decât cea a dispozitivului IQOS 3*</li>
							</ul>
						</li>
						<li>IQOS 3 DUO este prevăzut cu Sistemul IQOS ProtectPlus<sup>TM</sup> pentru o mai bună protecție și fiabilitate, precum și cu cip NFC pentru înregistrarea și accesul ușoare la website-ul IQOS </li>
					</ul>
					<p>
						<small>
							*cu condiția ca dispozitivul IQOS 3 DUO să fie încărcat complet pentru prima oară sau când este descărcat complet; se aplică pentru încărcarea dispozitivului IQOS 3 DUO de la utilizarea 0 la utilizarea 1. Poate depinde de condițiile de utilizare, de ciclul de viață al bateriei, condițiile meteo și utilizarea dispozitivului.
						</small>
					</p>
				`,
			},
			{
				title: 'Cum pot verifica nivelul de încărcare al bateriei?',
				content: `
					<p>
						Pentru a verifica nivelul de încărcare a bateriei portabile IQOS 3 DUO, apasă și eliberează butonul încărcătorului portabil.
					</p>
					<p>
						Indicatorul luminos al încărcătorului portabil se va aprinde timp de 3 secunde pentru a indica statusul bateriei:
					</p>
					<ul>
						<li>4 LED-uri = peste 75% încărcat</li>
						<li>1 LED = aproape descărcat; necesită reîncărcare.</li>
					</ul>
				`,
			},
			{
				title: 'Cât durează o încărcare completă a dispozitivului?',
				content: `
					<p>
						Durează aproximativ 120 de minute pentru ca încărcătorul portabil IQOS 3 DUO să fie complet încărcat de la o priză electrică dacă utilizezi adaptorul AC IQOS și cablul USB furnizate.
					</p>
				`,
			},
			{
				title: 'Ar trebui să îmi încarc  întotdeauna complet bateria încărcătorului portabil?',
				content: `
					<p>
						Dispozitivul IQOS 3 DUO nu necesită o încărcare completă pentru a funcționa, cu toate acestea recomandăm încărcarea completă a dispozitivului înaintea primei utilizări.					
					</p>
					<p>
						Dacă încărcătorul portabil IQOS 3 DUO este încărcat complet, poți utiliza dispozitivul de până la 20 de ori.
					</p>
				`,
			},
			{
				title: 'Pot încărca „stiloul” IQOS 2.4/2.4+ cu încărcătorul portabil IQOS 3 DUO și invers?',
				content: `
					<p>
						Nu, încărcătorul portabil IQOS 3 DUO este compatibil doar cu IQOS 3.	
					</p>
					<p>
						Dacă introduci dispozitivul IQOS 2.4+ în încărcătorul portabil IQOS 3 DUO, dispozitivul nu se va potrivi din cauza dimensiunii diferite.	
					</p>
					<p>
						Dacă introduci din greșeală dispozitivul IQOS 3 DUO în încărcătorul portabil IQOS 2.4+, LED-urile încărcătorului portabil vor lumina intermitent dar dispozitivul nu se va încărca.
					</p>
				`,
			},
			{
				title: 'Pot folosi adaptorul AC și cablul USB IQOS de la versiunea anterioară a dispozitivului pentru a încărca dispozitivul IQOS 3 DUO?',
				content: `
					<p>
						Poți folosi Adaptorul AC IQOS de la versiunile anterioare. Cu toate acestea, cablul USB IQOS 3 DUO este creat pentru un port USB-C, diferit de cel al IQOS 2.4+.
					</p>
				`,
			},
			{
				title: 'Cum îmi pot reseta dispozitivul IQOS 3 DUO?',
				content: `
					<p>
						Îți poți reseta ușor dispozitivul IQOS 3 DUO ținând apăsat butonul încărcătorului portabil timp de 10 secunde (până la stingerea LED-urilor) apoi eliberându-l.
					</p>
					<p>
						Toate LED-urile ce indică statusul bateriei se vor stinge, vor clipi de două ori, apoi se vor aprinde treptat pentru a confirma resetarea.
					</p>
				`,
			},
			{
				title: 'Cum verific starea „stiloului” pentru a afla câte utilizări sunt disponibile?',
				content: `
					<p>
						Poți verifica statusul dispozitivului (câte utilizări sunt disponibile fără reîncărcare) în două moduri - fie pe dispozitiv sau pe încărcătorul portabil:
					</p>
					<p>
						Verificarea statusului pe dispozitiv:
					</p>
					<ul>
						<li>
							Apasă și eliberează butonul dispozitivului:
							<ul>
								<li>2 LED-uri aprinse – gata pentru două utilizări</li>
								<li>1 LED aprins – gata pentru o utilizare</li>
								<li>Niciun LED aprins – dispozitivul nu este încărcat</li>
							</ul>
						</li>
					</ul>
					<p>
						Verificarea statusului dispozitivului pe încărcătorul portabil:
					</p>
					<ul>
						<li>
							Apasă și eliberează butonul încărcătorului portabil	
							<ul>
								<li>2 LED-uri aprinse – gata pentru două utilizări</li>
								<li>1 LED aprins – gata pentru o utilizare</li>
							</ul>
						</li>
					</ul>
					<p>
						<small>
							*Pentru utilizatorii care utilizează o versiune mixtă cu încărcarea dispozitivului IQOS 3 DUO în încărcătorul portabil IQOS 3 în unele cazuri poate fi necesară o aducere la zi a soft-ului încărcătorului portabil IQOS 3 pentru a ști când este gata dispozitivul pentru o utilizare. 
						</small>
					</p>
				`,
			},
			{
				title: 'Cum se adaptează IQOS 3 DUO ritmului meu de consum?',
				content: `
					<p>
						IQOS 3 DUO poate fi folosit de două ori consecutiv fără reîncărcarea dispozitivului. Două LED-uri albe, aprinse pe dispozitiv și încărcătorul portabil, vor indica acest lucru.
					</p>
					<p>
						De îndată ce ai terminat cu prima utilizare, va rămâne un singur LED aprins, adică 2 LED-uri aprinse - gata pentru 2 utilizări, 1 LED aprins - 1 utilizare.
					</p>
					<p>
						Dacă ai nevoie de cea de-a doua utilizare imediat, dispozitivul tău este pregătit instantaneu.
					</p>
					<p>
						Dacă nu ai nevoie imediat de a doua utilizare, pur și simplu pune dispozitivul la loc în încărcătorul portabil pentru o reîncărcare rapidă, pentru a avea din nou la dispoziție 2 utilizări. 
					</p>
					<p>
						În cele mai multe cazuri, poți încărca dispozitivul pentru a beneficia de o experiență mai rapid decât cu orice alt dispozitiv IQOS.
					</p>
				`,
			},
			{
				title: 'Ce înseamnă vibrațiile dispozitivului?',
				content: `
					<p>
						Vibrațiile dispozitivului IQOD 3 DUO sunt activate în următoarele situații:
					</p>
					<ul>
						<li>La începutul încălzirii (imediat ce apeși butonul dispozitivului pentru a-l porni) - o vibrație</li>
						<li>Când dispozitivul este gata de utilizare - două vibrații</li>
						<li>Când experiența se apropie de final (cu 30 de secunde înainte de final) - două vibrații.</li>
					</ul>
				`,
			},
			{
				title: 'Care este temperatura optimă de funcționare a dispozitivului IQOS 3 DUO?',
				content: `
					<p>
						IQOS 3 DUO este proiectat astfel încât să funcționeze într-un interval extins de temperaturi, între 0°C și 50°C.
					</p>
				`,
			},
			{
				title: 'Ce este NFC?',
				content: `
					<p>
						NFC provine de la Near-field communication, comunicare în câmp apropiat. Aceasta facilitează comunicarea a două dispozitive electronice, dispozitivul tău IQOS și telefonul tău, prin apropierea lor la o distanță de 4 cm.
					</p>
					<p>
						La IQOS, folosim această tehnologie pentru a oferi acces simplificat la experiența digitală (website-ul IQOS) utilizatorilor IQOS.
					</p>
					<p>
						Aceasta permite înregistrarea simplificată a utilizatorului/dispozitivului, integrarea simplă a dispozitivului pentru a învăța cum să folosești dispozivitul tău IQOS.
					</p>
					<p>
						NFC oferă acces simplificat la website-ul IQOS pentru:
					</p>
					<ul>
						<li>Înregistrarea utilizatorului/dispozitivului</li>
						<li>Inițierea rapidă privind utilizarea IQOS</li>
						<li>Acces cu o singură atingere la funcționalități cheie: Găsire magazin IQOS, contactare serviciu clienți, magazin online IQOS.</li>
					</ul>
				`,
			},
			{
				title: 'Cum activez funcția NFC?',
				content: `
					<p>
						Dacă telefonul tău are incorporat un chip NFC, trebuie doar să activezi NFC din meniul de setări al telefonului tău.
					</p>
					<p>
						Cele două dispozitive se vor conecta automat când le apropii unul de celălalt (la mai puțin de 4cm distanță) și pagina web IQOS se va deschide. Gata.
					</p>
				`,
			},
			{
				title: 'Cum îmi pot înregistra dispozitivul cu ajutorul codului NFC sau QR?',
				content: `
					<p>
						Sunt două moduri de a accesa website-ul IQOS pentru înregistrare: prin atingerea NFC sau cu ajutorul codului QR: 
					</p>
					<p>
						1. Înregistrare prin atingerea NFC:
					</p>
					<ul>
						<li>atinge telefonul tău de ușița încărcătorului portabil pentru a deschide interfața web pe ecranul telefonului tău</li>
						<li>completează informațiile necesare înregistrării. Reține că numărul de serie al dispozitivului este introdus automat.</li>
					</ul>
					<p>
						2. Înregistrare cu ajutorul codului QR:
					</p>
					<ul>
						<li>scanează codul QR de pe ambalajul dispozitivului (folia din pachet, ce conține documente dedicate utilizatorului) pentru a deschide interfața web</li>
						<li>fotografiază eticheta din pachet astfel încât să surprinzi numărul de serie al dispozitivului tău</li>
						<li>numărul de serie al dispozitivului este introdus automat. Dacă nu reușești, te rugăm să fotografiezi eticheta din exteriorul cutiei astfel încât să surprinzi numărul de serie al dispozitivului tău</li>
						<li>completează informațiile necesare înregistrării dispozitivului.</li>
					</ul>
				`,
			},
			{
				title: 'Cum pot actualiza software-ul încărcătorului meu portabil IQOS 3 astfel încât să îl utilizez împreună cu dispozitivul IQOS 3 DUO?',
				content: `
					<p>
						Dacă ai deja un dispozitiv IQOS 3 și vrei să utilizezi dispozitivul IQOS 3 DUO cu încărcătorul portabil IQOS 3, este posibil să fie necesară actualizarea soft-ului.	
					</p>
					<p>
						Pentru a-ți da seama dacă dispozitivul tău IQOS 3 actual are nevoie de o actualizare, parcurge pașii de mai jos:
					</p>
					<ol>
						<li>
							Încarcă complet dispozitivul tău IQOS 3 DUO și introdu-l în încărcătorul portabil IQOS 3
						</li>
						<li>
							Apasă butonul încărcătorului portabil pentru a verifica LED-ul ce indică statusul dispozitivului:
							<ol type="a">
								<li>Dacă LED-ul luminează intermitent o dată – actualizarea este necesară</li>
								<li>Dacă LED-ul luminează intermitent de două ori – actualizarea nu este necesară</li>
							</ol>
						</li>
					</ol>
					<p>
						Poți actualiza soft-ul dispozitivului tău în magazinele IQOS. Este gratuit.
					</p>
					<p>
						După actualizare vei putea încărca și utiliza dispozitivul tău IQOS 3 DUO cu încărcătorul portabil IQOS 3.
					</p>
				`,
			},
			{
				title: 'Ce este noul sistem ProtectPlus<sup>TM</sup>?',
				content: `
					<p>
						IQOS ProtectPlus<sup>TM</sup> redefinește conceptul de protecție și fiabilitate pentru dispozitivul IQOS:					
					</p>
					<ul>
						<li>carcasă exterioară robustă care îmbunătățește rezistența la șocuri a dispozitivului</li>
						<li>clapeta laterală testată pentru a garanta o mai bună performanță a dispozitivului </li>
						<li>camera electronică (unde sunt situate elementele electronice) reconcepută a dispozitivului IQOS 3 DUO oferă o fiabilitate îmbunătățită a dispozitivului.</li>
					</ul>
				`,
			},
		]
	},
	{
		categoryTitle: 'IQOS 3 Multi',
		categoryId: 'iqos-3-multi',
		categoryQuestions: [
			{
				title: 'Cum pot verifica nivelul de încărcare al bateriei?',
				content: `
					<p>
						Poți verifica cu ușurință nivelul de încărcare urmând acești pași simpli: 1. Apăsați butonul multifuncțional timp de 1 secundă 2. Indicatoarele de nivel al bateriei se vor aprinde, indicând nivelul de încărcare.
					</p>
				`,
			},
			{
				title: 'Cât durează încărcarea completă a dispozitivului?',
				content: `
					<p>
						Este nevoie de aproximativ 75 de minute pentru a încărca complet dispozitivul IQOS 3 Multi de la o priză electrică. Încarcă dispozitivul IQOS 3 Multi numai utilizând adaptorul de alimentare AC și cablul USB.
					</p>
				`,
			},
			{
				title: 'Ar trebui să îmi încarc complet întotdeauna încărcătorul portabil?',
				content: `
					<p>
						Este nevoie de aproximativ 75 de minute pentru a încărca complet dispozitivul IQOS 3 Multi de la o priză electrică. Încarcă dispozitivul IQOS 3 Multi numai utilizând adaptorul de alimentare AC și cablul USB.
					</p>
				`,
			},
			{
				title: 'Cum pot reseta dispozitivul IQOS?',
				content: `
					<p>
						Poți reseta cu ușurință dispozitivul IQOS 3 Multi apăsând și ținând apăsat butonul încărcătorului portabil timp de 10 secunde. Ledurile se vor stinge/aprinde încet pentru a confirma o resetare.
					</p>
				`,
			},
			{
				title: 'De ce vibrează dispozitivul?',
				content: `
					<p>
						Vibrațiile IQOS 3 Multi sunt activate în următoarele situații: - La pornirea încălzirii (odată ce apeși butonul suportului pentru a porni) - Când experiența este aproape completă (30 de secunde înainte de sfârșit).
					</p>
				`,
			},
			{
				title: 'Ce înseamnă ledurile de pe IOQS 3 Multi?',
				content: `
					<p>
						Orice lumină albă indică faptul că dispozitivul efectuează diferite operații, cum ar fi încărcarea, repornirea, faptul că e gata pentru utilizare, încărcare completă etc. Orice lumină roșie indică faptul că există o eroare a dispozitivului sau dispozitivul funcționează în afara temperaturii de funcționare (0 - 50°C). Te rugăm să efectuezi o resetare a dispozitivului.
					</p>
				`,
			}
		]
	},
	{
		categoryTitle: 'IQOS 2.4+',
		categoryId: 'iqos-2-4-plus',
		categoryQuestions: [
			{
				title: 'Cum pot verifica nivelul de încărcare al bateriei?',
				content: `
					<p>
						Poți verifica cu ușurință nivelul de încărcare urmând acești pași simpli: 1. Apasă butonul ON/OFF timp de o secundă. 2. LED-urile se vor aprinde și vor indica nivelul de încărcare: - 1 LED = nivel redus de încărcare - 4 LED-uri = dispozitivul este complet încărcat.
					</p>
				`,
			},
			{
				title: 'Cât durează încărcarea completă a dispozitivului?',
				content: `
					<p>
						Este nevoie de aproximativ 90 de minute pentru a încărca complet încărcătorul portabil IQOS 2.4 Plus de la o priză electrică. Încarcă dispozitivul IQOS 2.4 Plus numai utilizând adaptorul de alimentare AC și cablul USB.
					</p>
				`,
			},
			{
				title: 'Ar trebui să îmi încarc complet întotdeauna bateria încărcătorului portabil?',
				content: `
					<p>
						Dispozitivul IQOS nu necesită o încărcare completă pentru a funcționa. Însă, dacă încărcătorul portabil este complet încărcat, poți utiliza ”stiloul” de până la 20 de ori.
					</p>
				`,
			},
			{
				title: 'Cum pot reseta dispozitivul IQOS?',
				content: `
					<p>
						Poți efectua cu ușurință o resetare a dispozitivului IQOS 2.4 Plus apăsând simultan și apoi eliberând butoanele Bluetooth și ON/OFF până când toate ledurile clipesc scurt.
					</p>
				`,
			},
			{
				title: 'Ce înseamnă vibrațiile dispozitivului?',
				content: `
					<p>
						Vibrațiile ”stiloului” sunt activate în următoarele situații: - Când procesul de încălzire a pornit - Când experiența este aproape completă (30 de secunde sau 2 pufuri înainte de final).
					</p>
				`,
			},
			{
				title: 'Ce înseamnă lumina roșie de pe ”stiloul” meu?',
				content: `
					<p>
						Lumina roșie care clipește sau rămâne constantă poate indica faptul că: - ”Stiloul” nu este încărcat - Dispozitivul este în afara temperaturii optime de funcționare (10 - 40°C). - Ai apăsat butonul suportului în timpul utilizării - ”Stiloul” are o defecțiune Pentru a rezolva problema, puteți încerca următoarele:
					</p>
					<ul>
						<li>Verifică dacă încărcătorul portabil IQOS 2.4 Plus este pornit și are suficient curent. Pune ”stiloul” în încărcătorul portabil și așteaptă până când acesta este complet încărcat (până la 4 minute)</li>
						<li>Asigură-te că contactul electric de la baza ”stiloului” este curățat</li>
						<li>Încearcă să resetezi dispozitivul. Dacă întâmpini probleme în continuare, te rugăm să contactezi centrul de Asistență clienți 0800030333 (luni - duminică: 08:00 - 22:00), e-mail: contact@iqos.ro, Facebook: https://www.facebook.com/iqos.ro, Twitter: @iqos_support_ro) sau să ceri asistență la cel mai apropiat magazin IQOS.</li>
					</ul>
				`,
			},
			{
				title: 'Ce înseamnă lumina roșie de pe încărcătorul meu portabil?',
				content: `
					<p>
						Lumina roșie de pe încărcătorul portabil IQOS 2.4 Plus indică o defecțiune a suportului. O lumină roșie care pulsează încet în timpul încărcării înseamnă că bateria suportului atinge sfârșitul ciclului său de viață.
					</p>
					<p>
					Indicatorul luminos de jos al bateriei încărcătorului portabil indică o defecțiune a acestuia sau dispozitivul este în afara temperaturii optime de funcționare (10°C - 40°C).
					</p>
					<p>
					Încearcă să resetezi dispozitivul și asigură-te că acesta se află în intervalul optim de temperatură. Dacă întâmpini probleme în continuare, te rugăm să contactezi centrul de Asistență clienți 0800030333 (luni - duminică: 08:00 - 22:00), e-mail: contact@iqos.ro, Facebook: https://www.facebook.com/iqos.ro, Twitter: @iqos_support_ro) sau să ceri asistență la cel mai apropiat magazin IQOS.
					</p>
				`,
			},
			{
				title: 'Care este temperatura optimă de funcționare a dispozitivului IQOS?',
				content: `
					<p>
						IQOS 2.4 Plus este proiectat să funcționeze într-o gamă largă de temperaturi, dar recomandăm utilizarea între 10<sup>o</sup>C și 40<sup>o</sup>C pentru o experiență optimă.
					</p>
				`,
			},
			{
				title: 'Ce este noul sistem ProtectPlus<sup>TM</sup>?',
				content: `
					<p>
						Sistemul ProtectPlus<sup>TM</sup> găzduiește o serie de îmbunătățiri tehnologice: - carcasa exterioară robustă îmbunătățește rezistența la șoc a dispozitivului - o nouă deschidere a ușii laterale testată pentru a garanta o performanță sigură - noua tehnologie oferă o durată mai lungă de viață a bateriei - camera de încălzire a suportului a fost reproiectată pentru o fiabilitate îmbunătățită.
					</p>
				`,
			}
		]
	},
	{
		categoryTitle: 'IQOS 3',
		categoryId: 'iqos-3',
		categoryQuestions: [
			{
				title: 'Cum pot verifica nivelul de încărcare al bateriei?',
				content: `
					<p>
						Poți verifica cu ușurință nivelul bateriei urmând acești pași simpli: 1. Apasă butonul de încărcare al încărcătorului portabil pentru 1 secundă (apăsați scurt) 2. Indicatoarele de nivel al bateriei se vor aprinde.
					</p>
				`,
			},
			{
				title: 'Cât durează încărcarea completă a dispozitivului?',
				content: `
					<p>
						Este nevoie de aproximativ 120 de minute pentru a încărca complet încărcătorul de buzunar IQOS 3 de la o priză electrică. Încarcă dispozitivul IQOS 3 utilizând adaptorul de alimentare AC și cablul USB.
					</p>
				`,
			},
			{
				title: 'Ar trebui să îmi încarc complet întotdeauna bateria încărcătorului de buzunar?',
				content: `
					<p>
						Dispozitivul IQOS 3 nu necesită o încărcare completă pentru a funcționa. Dacă încărcătorul de buzunar IQOS 3 este încărcat complet, poți utiliza suportul de până la 20 de ori.
					</p>
				`,
			},
			{
				title: 'Pot încărca suportul 2.4/2.4+ cu încărcătorul de buzunar IQOS 3 și invers?',
				content: `
					<p>
						Nu, noul încărcător portabil IQOS 3 nu este compatibil cu versiunile anterioare. Dacă introduci holder-ul (”stiloul”) 2.4+ în încărcătorul portabil IQOS 3, suportul nu se va încărca din cauza dimensiunilor diferite. Holder-ul (”stiloul”) IQOS 3 nu poate fi introdus în încărcătorul portabil 2.4/2.4, datorită dimensiunilor diferite.
					</p>
				`,
			},
			{
				title: 'Pot folosi adaptorul de alimentare AC și cablul USB de la versiunea anterioară a dispozitivului pentru a încărca dispozitivul IQOS 3?',
				content: `
					<p>
						Poți utiliza adaptorul de alimentare AC IQOS din versiunile anterioare ale dispozitivului. Cu toate acestea, cablul USB IQOS 3 este un cablu de tip USB-C, diferit de versiunile anterioare.
					</p>
				`,
			},
			{
				title: 'Cum pot reseta dispozitivul IQOS?',
				content: `
					<p>
						Poți reseta cu ușurință dispozitivul IQOS 3 ținând apăsat butonul încărcătorului portabil timp de 8 secunde, apoi eliberându-l. Indicatorii de nivel al bateriei vor clipi de două ori, apoi se vor stinge progresiv pentru a confirma resetarea.
					</p>
				`,
			},
			{
				title: 'Ce înseamnă vibrațiile dispozitivului?',
				content: `
					<p>
						Vibrațiile holder-ului (”stiloului”) sunt activate în următoarele situații: - La pornirea încălzirii (odată ce apeși butonul holder-ului (”stiloului”) pentru a începe experiența) - Când experiența este aproape completă (30 de secunde înainte de sfârșit).
					</p>
				`,
			},
			{
				title: 'Ce semnifică ledurile de pe holder (”stilou”)?',
				content: `
					<p>
						IQOS 2.4 Plus are următoarele caracteristici:
					</p>
					<ul>
						<li>Holder (”stilou”) IQOS: încărcare mai rapidă (până la 35% mai rapidă)</li>
						<li>Vibrația holder-ului (”stiloului”) IQOS</li>
						<li>încărcător portabil robust (dispozitiv de blocare îmbunătățit)</li>
						<li>leduri mai luminoase pe încărcătorul portabil IQOS</li>
						<li>Curățarea automată înlocuiește curățarea manuală Vibrația holder-ului (”stiloului”) IQOS:</li>
						<li>Vibrația începe și ledurile clipesc când apeși și ții apăsat butonul de încălzire. Astfel se indică începutul experienței (timp de 6 minute sau 14 fumuri, oricare dintre acestea apare mai întâi).</li>
						<li>Ledurile încep să clipească în același timp cu vibrația când au rămas 30 de secunde sau două pufuri. Acest lucru indică faptul că holder-ul (”stiloul”) IQOS se va opri automat. Curățarea automată:</li>
						<li>Curățarea automată este declanșată automat la fiecare 20 de încărcări, după ce holder-ul (”stiloul”) IQOS a terminat procesul de încărcare.</li>
						<li>În timpul curățării automate, vei observa un proces de încărcare mai lung al holder-ului (”stiloului”) (reîncărcare - curățare - reîncărcare), iar ledurile vor clipi constant.</li>
					</ul>
				`,
			},
			{
				title: 'Ce înseamnă ledurile de pe încărcătorul portabil?',
				content: `
					<p>
						Orice lumină albă indică faptul că dispozitivul efectuează diferite operații, cum ar fi încărcarea, repornirea, momentul când e gata de utilizare, încărcarea completă etc.
					</p>
					<p>O lumină roșie intermitentă poate apărea dacă este aprins numai indicatorul luminos al încărcătorului portabil: dispozitivul este în afara temperaturii de funcționare (0 - 50°C).
					</p>
				`,
			},
			{
				title: 'Ce este noul sistem ProtectPlus<sup>TM</sup>?',
				content: `
					<p>
						Sistemul ProtectPlus<sup>TM</sup> găzduiește o serie de îmbunătățiri tehnologice: - carcasa exterioară robustă îmbunătățește rezistența la șoc a dispozitivului - o nouă deschidere a ușii laterale testată pentru a garanta o performanță sigură - noua tehnologie oferă o durată mai lungă de viață a bateriei - camera de încălzire a suportului a fost reproiectată pentru o fiabilitate îmbunătățită.
					</p>
				`,
			},
			{
				title: 'Care este temperatura optimă de funcționare a dispozitivului IQOS?',
				content: `
					<p>
						IQOS 3 este proiectat să funcționeze într-o gamă largă de temperaturi, dar recomandăm utilizarea între 12<sup>o</sup>C și 50<sup>o</sup>C pentru o experiență optimă.
					</p>
				`,
			},
			{
				title: 'Ce este sistemul de auto-curățare a lamelei de încălzire?',
				content: `
					<p>
						Procesul de auto-curățare a lamelei de încălzire este activat automat după fiecare 20 de sesiuni, atunci când holder-ul (”stiloul”) se încarcă. Acest proces reduce reziduurile de tutun de pe lamelă, facilitând procesul de curățare manuală, asigurând în același timp cea mai bună experiență de savurare a tutunului. Procesul de auto-curățare al lamelei poate fi activat și prin intermediul aplicației IQOS Connect la cerere.
					</p>
				`,
			}
		]
	},
	// {
	// 	categoryTitle: 'IQOS 2.4+',
	// 	categoryId: 'iqos-2-4-plus',
	// 	categoryQuestions: [
	// 		{
	// 			title: 'Cum pot verifica nivelul de încărcare al bateriei?',
	// 			content: `
	// 				<p>
	// 					Poți verifica cu ușurință nivelul de încărcare urmând acești pași simpli: 1. Apasă butonul ON/OFF timp de o secundă. 2. LED-urile se vor aprinde și vor indica nivelul de încărcare: - 1 LED = nivel redus de încărcare - 4 LED-uri = dispozitivul este complet încărcat.
	// 				</p>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Cât durează încărcarea completă a dispozitivului?',
	// 			content: `
	// 				<p>
	// 					Este nevoie de aproximativ 90 de minute pentru a încărca complet încărcătorul portabil IQOS 2.4 Plus de la o priză electrică. Încarcă dispozitivul IQOS 2.4 Plus numai utilizând adaptorul de alimentare AC și cablul USB.
	// 				</p>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Ar trebui să îmi încarc complet întotdeauna bateria încărcătorului portabil?',
	// 			content: `
	// 				<p>
	// 					Dispozitivul IQOS nu necesită o încărcare completă pentru a funcționa. Însă, dacă încărcătorul portabil este complet încărcat, poți utiliza ”stiloul” de până la 20 de ori.
	// 				</p>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Cum pot reseta dispozitivul IQOS?',
	// 			content: `
	// 				<p>
	// 					Poți efectua cu ușurință o resetare a dispozitivului IQOS 2.4 Plus apăsând simultan și apoi eliberând butoanele Bluetooth și ON/OFF până când toate ledurile clipesc scurt.
	// 				</p>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Ce înseamnă vibrațiile dispozitivului?',
	// 			content: `
	// 				<p>
	// 					Vibrațiile ”stiloului” sunt activate în următoarele situații: - Când procesul de încălzire a pornit - Când experiența este aproape completă (30 de secunde sau 2 pufuri înainte de final).
	// 				</p>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Ce înseamnă lumina roșie de pe ”stiloul” meu?',
	// 			content: `
	// 				<p>
	// 					Lumina roșie care clipește sau rămâne constantă poate indica faptul că: - ”Stiloul” nu este încărcat - Dispozitivul este în afara temperaturii optime de funcționare (10 - 40°C). - Ai apăsat butonul suportului în timpul utilizării - ”Stiloul” are o defecțiune Pentru a rezolva problema, puteți încerca următoarele:
	// 				</p>
	// 				<ul>
	// 					<li>Verifică dacă încărcătorul portabil IQOS 2.4 Plus este pornit și are suficient curent. Pune ”stiloul” în încărcătorul portabil și așteaptă până când acesta este complet încărcat (până la 4 minute)</li>
	// 					<li>Asigură-te că contactul electric de la baza ”stiloului” este curățat</li>
	// 					<li>Încearcă să resetezi dispozitivul. Dacă întâmpini probleme în continuare, te rugăm să contactezi centrul de Asistență clienți 0800030333 (luni - duminică: 08:00 - 22:00), e-mail: contact@iqos.ro, Facebook: https://www.facebook.com/iqos.ro, Twitter: @iqos_support_ro) sau să ceri asistență la cel mai apropiat magazin IQOS.</li>
	// 				</ul>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Ce înseamnă lumina roșie de pe încărcătorul meu portabil?',
	// 			content: `
	// 				<p>
	// 					Lumina roșie de pe încărcătorul portabil IQOS 2.4 Plus indică o defecțiune a suportului. O lumină roșie care pulsează încet în timpul încărcării înseamnă că bateria suportului atinge sfârșitul ciclului său de viață.
	// 				</p>
	// 				<p>
	// 				Indicatorul luminos de jos al bateriei încărcătorului portabil indică o defecțiune a acestuia sau dispozitivul este în afara temperaturii optime de funcționare (10°C - 40°C).
	// 				</p>
	// 				<p>
	// 				Încearcă să resetezi dispozitivul și asigură-te că acesta se află în intervalul optim de temperatură. Dacă întâmpini probleme în continuare, te rugăm să contactezi centrul de Asistență clienți 0800030333 (luni - duminică: 08:00 - 22:00), e-mail: contact@iqos.ro, Facebook: https://www.facebook.com/iqos.ro, Twitter: @iqos_support_ro) sau să ceri asistență la cel mai apropiat magazin IQOS.
	// 				</p>
	// 			`,
	// 		},
	// 		{
	// 			title: 'Care este temperatura optimă de funcționare a dispozitivului IQOS?',
	// 			content: `
	// 				<p>
	// 					IQOS 2.4 Plus este proiectat să funcționeze într-o gamă largă de temperaturi, dar recomandăm utilizarea între 10<sup>o</sup>C și 40<sup>o</sup>C pentru o experiență optimă.
	// 				</p>
	// 			`,
	// 		},
	// 	]
	// },
	{
		categoryTitle: 'Despre IQOS',
		categoryId: 'despre-iqos',
		categoryQuestions: [
			{
				title: 'IQOS se încadrează în categoria ţigărilor electronice? Cum se deosebeşte?',
				content: `
					<p>
						IQOS nu este o țigară electronică, ci un aparat electronic care încălzeste, nu arde, produsele din tutun comercializate sub numele de HEETS. Spre deosebire de țigările electronice, utilizarea IQOS nu implică utilizarea unor lichide (de exemplu, lichid care conține nicotină) pentru a inhala, ulterior, prin intermediul unui muștiuc, vapori ce conțin nicotină. HEETS nu sunt flacoane de reumplere a țigaretei electronice, ci un produs din tutun reglementat separat din punct de vedere legal.
					</p>
				`,
			},
			{
				title: 'Ce conține o rezervă de tutun HEETS?',
				content: `
					<p>
						IQOS este o alternativă la fumatul obișnuit deoarece încălzește tutunul în loc să îl ardă. Oferă gust autentic de tutun fără foc, fără fum de țigară și cu mai puțin miros. Trecerea completă la IQOS prezintă un risc redus pentru sănătatea ta față de continuarea fumatului.*
						<br />IQOS eliberează cu 95% mai puține substanțe nocive, în comparație cu țigările.*
						<br />Informație importantă: IQOS nu este lipsit de riscuri. Emană nicotină, substanță ce dă dependență.
						<br />*Reduceri medii ale nivelurilor unei game largi de substanțe nocive (mai puțin nicotina) în comparație cu fumul emanat de o țigară de referință (3R4F). Aceasta nu înseamnă neapărat că există o reducere cu 95% a riscurilor. IQOS nu este lipsit de riscuri.
					</p>
				`,
			},
			{
				title: 'Este adevărat că IQOS nu arde tutunul și doar îl încălzește? Care este temperatura de funcționare a „stiloului” IQOS?',
				content: `
					<p>
						IQOS include un aparat electronic, denumit de consumatori „stilou”, care încălzește tutunul conținut într-un HEETS, la o temperatură mai mică de 350°C, fără a-l arde, pentru a crea un aerosol ce conține nicotină. Prin comparație, tutunul dintr-o țigară cu aprindere ajunge la o temperatură de până la 800°C.
					</p>
				`,
			},
			{
				title: 'IQOS generează fum? Dar scrum?',
				content: `
					<p>
						IQOS doar încălzește tutunul în loc să-l ardă si astfel nu generează nici fum si nici scrum. Încălzirea, în loc de arderea tutunului, eliberează un aerosol care permite adulților fumători să se bucure de aroma autentică a tutunului, oferind o experiență mai curată comparativ cu țigările. IQOS are astfel si un miros mai puțin evident. Spre deosebire de fumul expirat din ţigări, aerosolul generat de HEETS<sup>TM</sup> este mai puţin supărător pentru cei din jur și se disipează rapid.
					</p>
				`,
			},
			{
				title: 'Este folosirea IQOS sigură/fără riscuri?',
				content: `
					<p>
						IQOS eliberează cu 95% mai puține substanțe nocive, în comparație cu țigările.*
						<br />Informație importantă: Aceasta nu înseamnă neapărat că există o reducere cu 95% a riscurilor. IQOS nu este lipsit de riscuri.
						<br />*Reduceri medii ale nivelurilor unei game largi de substanțe nocive (mai puțin nicotina) în comparație cu fumul emanate de o țigară de referință (3R4F).
					</p>
				`,
			},
			{
				title: 'Pot utiliza o ţigară convenţională cu „stiloul” IQOS?',
				content: `
					<p>
						Nu. „Stiloul” IQOS este conceput să funcționeze doar cu HEETS. Țigările cu aprindere nu vor funcționa cu sistemul IQOS.
					</p>
				`,
			},
			{
				title: 'Cât timp poate fi consumat un HEETS după ce este încălzit?',
				content: `
					<p>
						Sistemul IQOS este conceput pentru a încălzi rezerva de tutun HEETS pentru un interval de timp şi un număr de experiențe aproximativ similar cu al unei țigări combustibile, dar fără a arde tutunul. Puteți utiliza în mod continuu HEETS timp de aproximativ 6 minute sau 14 inhalări, oricare survine prima.
					</p>
				`,
			},
			{
				title: 'De câte ori îmi pot încărca „stiloul” IQOS cu ajutorul încărcătorului portabil înainte de a fi nevoie de reîncărcarea acestuia?',
				content: `
					<p>
						Poţi reîncărca „stiloul” IQOS de 20 de ori folosind încărcătorul portabil IQOS complet încărcat. Astfel te poţi bucura de 20 de HEETS<sup>TM</sup> înainte de reîncărcarea încărcătorului portabil.
					</p>
				`,
			},
			{
				title: 'Doar ce am achiziționat dispozitivul IQOS. Oferiți asistență personalizată? Este gratuită?',
				content: `
					<p>
						Încurajăm utilizatorii adulți IQOS să apeleze la centrul de Asistență Clienți (de luni până vineri între orele 08:00 și 20:00, iar în weekend între orele 10:00 și 18:00). Agenții noștri vor fi alături de tine pentru a îți răspunde la întrebări prin telefon, e-mail, Facebook (https://www.facebook.com/iqos.ro) și Twitter (https://www.twitter.com_iqos_support_ro), în funcție de preferințele tale.
					</p>
				`,
			},
			{
				title: 'Mă ajută IQOS să renunț la fumat?',
				content: `
					<p>
						IQOS/HEETS nu sunt o alternativă pentru renunțarea la fumat. Cea mai bună alegere pentru consumatorii preocupați de sănătatea lor este să renunțe complet la tutun.
					</p>
				`,
			},
			{
				title: 'Pot încărca IQOS şi în maşină? Aveţi produse care să facă posibil acest lucru?',
				content: `
					<p>
						Încărcătorul portabil IQOS poate fi utilizat în mașinile care dețin port de încărcare prin conectarea cablului USB oferit împreună cu IQOS.
					</p>
				`,
			},
			{
				title: 'Cum pot întreţine zilnic în mod corespunzător acest produs?',
				content: `
					<p>
						Recomandăm curăţarea zilnică a lamei de încălzire a „stiloului”şi a capacului utilizând peria de curăţare. De asemenea, produsul se curăţă automat printr-un proces termic după fiecare 20 HEETS utilizate, ca parte a unui proces de întreţinere regulat. Pentru detalii suplimentare poţi consulta ghidul utilizatorului din pachet sau poţi accesa www.IQOS.ro, secţiunea Tutoriale produse.
					</p>
				`,
			},
			{
				title: 'Accesoriile unui dispozitiv IQOS sunt compatibile cu alte dispozitive IQOS?',
				content: `
					<p>
						Doar accesoriile IQOS 2.4+ sunt compatibile cu IQOS 2.4, cele două dispozitive având aceleași dimensiuni. În cazul IQOS 3 și IQOS 3 Multi, accesoriile nu sunt compatibile cu nicio altă versiune a dispozitivului și pot fi folosite doar cu dispozitivul pentru care au fost destinate.
					</p>
				`,
			},
			{
				title: 'Ce trebuie să fac dacă o parte a sistemului IQOS nu mai funcţionează?',
				content: `
					<p>
						Pentru mai multe detalii legate de serviciile şi condiţiile de înlocuire poți contacta gratuit Centrul pentru Asistență Clienţi la numărul 0800.030.333, de luni până vineri între orele 08:00 și 20:00, iar în weekend între orele 10:00 și 18:00. În momentul apelului este necesar să comunici colegilor noștri codul de identificare a „stiloului”/încărcătorului afectat (îl poți găsi inscripționat pe componenta respectivă). După apelul tău, în cazul în care va fi necesară înlocuirea sistemului tău IQOS, regăsești aici punctele de asistență https://www.iqos.ro/iqos-service-points.html. Alternativ, poți merge direct în magazinele proprii IQOS accesând https://www.iqos.ro/unde-gasesc-iqos, secțiunea IQOS Stores.
					</p>
				`,
			},
			{
				title: 'Cât timp ar trebui să funcţioneze IQOS?',
				content: `
					<p>
						„Stiloul” IQOS este conceput să reziste la 7300 de utilizări, dacă este folosit conform instrucțiunilor din Ghidului Utilizatorului care se regăsește în pachetul inițial.
					</p>
				`,
			},
			{
				title: 'Cât timp este valabil programul de garanţie al produsului?',
				content: `
					<p>
						Garanţia producătorului este valabilă timp de un an de la data achiziţiei sau 7300 de încărcări ale încărcătorului portabil/7300 de utilizări ale „stiloului” (oricare din acestea survine mai devreme).
					</p>
				`,
			},
			{
				title: 'IQOS îndeplineşte cerinţele de siguranţă aplicabile dispozitivelor electronice?',
				content: `
					<p>
						IQOS îndeplinește cerințele legale de siguranță dacă este utilizat în conformitate cu instrucțiunile menționate în Ghidul Utilizatorului care se regăsește în pachetul inițial. „Stiloul” IQOS şi încărcătorul portabil respectă cerințele de siguranță ale UE pentru dispozitive electronice de larg consum, aspect indicat prin marcajul „CE” aplicat pe ambele produse.
					</p>
				`,
			},
			{
				title: 'Unde pot găsi locaţiile care oferă servicii de înlocuire?',
				content: `
					<p>
						Pentru mai multe detalii legate de serviciile şi condiţiile de înlocuire poți contacta gratuit Centrul pentru Asistență Clienţi la numărul 0800.030.333, de luni până vineri între orele 08:00 și 20:00, iar în weekend între orele 10:00 și 18:00. În momentul apelului este necesar să comunici colegilor noștri codul de identificare a „stiloului”/încărcătorului afectat (îl poți găsi inscripționat pe componenta respectivă). După apelul tău, în cazul în care va fi necesară înlocuirea sistemului tău IQOS, regăsești aici punctele de asistență . Alternativ, poți merge direct în magazinele IQOS, a căror adresă o poți găsi aici, secțiunea IQOS Stores.
					</p>
				`,
			},
			{
				title: 'Unde se află certificatul de garanţie?',
				content: `
					<p>
						Certificatul de garanţie însoţește toate componentele IQOS (cu excepţia dispozitivului de curăţare), iar condiţiile de garanţie sunt menţionate în ghidul utilizatorului care se regaseste in pachet.
					</p>
				`,
			},
			{
				title: 'Cine/ce este acoperit de garanția internațională IQOS?',
				content: `
					<p>
						Serviciul de asistență specială este aplicabil numai dacă sunt îndeplinite toate cele trei condiții: (i) ești un consumator IQOS adult din UE sau Elveția; (ii) tu și dispozitivul tău IQOS sunteți înregistrați în UE sau în Elveția; și (iii) întâmpini o problemă cu dispozitivul IQOS atunci când călătorești în UE și Elveția, în afara țării în care este înregistrat dispozitivul.
					</p>
				`,
			},
			{
				title: 'Cât timp durează acoperirea în cadrul garanției internaționale?',
				content: `
					<p>
						Acest serviciu este oferit în perioada de garanție comercială IQOS, valabilă în țara de achiziție.
					</p>
				`,
			},
			{
				title: 'Cum funcționează serviciul special de garanție internațională?',
				content: `
					<p>
						Dacă ai nevoie de asistență în străinătate, contactează, gratuit, numărul de asistență pentru clienți IQOS, *0080023723888 pentru ajutor. Înainte de a efectua apelul, asigură-te că ai la îndemână adresa temporară completă (de exemplu, numele și adresa hotelului). Se pot aplica limitări de conectivitate cu unii operatori de telefonie mobilă. În cazul puțin probabil în care se întâmplă acest lucru, ne poți contacta la +302112116043 (pot fi percepute anumite taxe; te rugăm să consulți operatorul tău pentru detalii).
					</p>
				`,
			},
			{
				title: 'În ce țări se aplică serviciul special de garanție internațională?',
				content: `
					<p>
						Serviciul de asistență IQOS este disponibil pentru consumatorii adulți IQOS care locuiesc și călătoresc în următoarele țări: 
						Albania, 
						Armenia, 
						Aruba, 
						Austria, 
						Bosnia, 
						Bulgaria, 
						Canada, 
						Columbia,
						Costa Rica,
						Croația,
						Curacao,
						Cipru,
						Republica Cehă,
						Danemarca,
						Republica Dominicană,
						Egipt,
						Estonia,
						Franța,
						Georgia,
						Germania,
						Grecia,
						Guatemala,
						Indonezia,
						Israel,
						Italia,
						Japonia,
						Iordania,
						Kazakhstan,
						Kuwait,
						Kirgistan,
						La Reunion,
						Letonia,
						Liban,
						Lituania,
						Malaysia,
						Mexic,
						Moldova,
						Muntenegru,
						Maroc,
						Olanda,
						Noua Zeelandă,
						Macedonia de Nord,
						Palestina,
						Filipine,
						Polonia,
						Portugalia,
						România,
						Rusia,
						Arabia Saudită,
						Serbia,
						Slovacia,
						Slovenia,
						Africa de Sud,
						Coreea de Sud,
						Spania,
						Suedia,
						Elveția,
						Tunisia,
						Cipru Turc,
						Ucraina,
						Emiratele Arabe Unite,
						Regatul Unit,
						Uzbekistan
					</p>
				`,
			}
		]
	},

]