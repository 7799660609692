import Vue from 'vue';
import Vuex from 'vuex';
import config from "@/config";
import faq from "@/faq";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pwa: {
			message: null,
			type: null
		},
		settings: {
			window: {
				dimensions: {
					width: 0,
					height: 0
				}
			}
		},
		endPage: 0,
		nextVideo: "",
		nextVideoNr: 0,
		currentVideo: "",
		currentVideoIndex: null,
		showAppHeader: false,
		config,
        faq,
        isMob: false,
	},
	getters: {
		devices: state => {
			return state.config.devices;
		},
		faq: state => {
			return state.faq;
		}
	},
    mutations: {
        detectDevice (state) {
            var deviceOrientation = window.screen.orientation;
            if (screen.width <= 760) {
                state.isMob = true;
                deviceOrientation.lock('portrait-primary');
                // screen.lockOrientation("portrait-primary");
                // console.log('oreintation is:', screen.orientation, window.screen.orientation)
            } else {
                state.isMob = false;
                // deviceOrientation.unlock();
            }
        },
		setProp: (state, { prop, value }) => {
			Vue.set(state, prop, value);
		},
		setEndPage: (state, value) => {
			state.endPage = value;
		},
		setnextVideoNr: (state, value) => {
			state.nextVideoNr = value;
		},
		setcurrentVideo: (state, value) => {
			state.currentVideo = value;
			state.currentVideoIndex = parseInt(value.replace('video-','')) - 1;
		},
		setnextVideo: (state, value) => {
			state.nextVideo = value;
		},
		setWindowSize: (state, payload) => {
			state.settings.window.dimensions.width = payload.clientWidth;
			state.settings.window.dimensions.height = payload.clientHeight;
		}
	},
	actions: {
		backToVideos: function ({
			commit,
			dispatch
		}) {
			var videoPlay = document.getElementById(this.state.currentVideo);
			if (videoPlay) videoPlay.webkitExitFullScreen();
			commit('setEndPage', 0);
		},
		ended: function ({
			commit
		}, event) {
			event.currentTarget.webkitExitFullscreen();
			event.currentTarget.currentTime = '0';
		},
		stopFull: function ({
			commit
		}, event) {
			if ((document.fullScreen == false) || (document.mozFullScreen == false) || (document.webkitIsFullScreen == false)) {
				event.currentTarget.pause();
				event.currentTarget.currentTime = '0';
			}
		},
		playVideo: ({
			state
		}, videoId) => {
			var videoPlay = document.getElementById(videoId);
			if (videoPlay) {

				videoPlay.play();
				if (videoPlay.requestFullscreen) {
					videoPlay.requestFullscreen();
				} else if (videoPlay.msRequestFullscreen) {
					videoPlay.msRequestFullscreen();
				} else if (videoPlay.mozRequestFullScreen) {
					videoPlay.mozRequestFullScreen();
				} else if (videoPlay.webkitRequestFullScreen) {
					videoPlay.webkitRequestFullScreen();
					/*
					 *Kept here for reference: keyboard support in full screen
					 * videoPlay.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
					 */
				}
			}
		}
	}
})