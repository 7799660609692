<template>
	<div id="app">
		<div v-if="this.$route.name === 'intro' || this.$route.name === 'how-to-use' ? false : true">
			<div v-if="IOS">
				<div v-show="addApp" class="ios-bar-install ios-bar-install--top-56">
					<div class="w-full ios-bar-install__left">
						<button @click="howAddApp = true" class="btn--add-app btn--arrow text-20 font-bold  text-left" type="button">
							<div class="w-full pl-30 py-25">
								<span>Adaugă IQOS Pocket<br> pe Home screen
									<img src="@/assets/icons-functional-arrow-right.svg" class="inline"/>
								</span>
							</div>
						</button>
					</div>
					<div>
						<button @click="closeAddApp" class="btn--close-app pr-30 pt-48" type="button">
							<img src="@/assets/icons-functional-close.svg" class="w-35"/>
						</button>
					</div>
				</div>
				<div v-show="howAddApp" class="ios-how-install">
					<div class="ios-how-install__inner">
						<button @click="closeAddApp" class=" btn--close-how-install absolute top-15 right-15" type="button">
							<img src="@/assets/icons-functional-close.svg" class="w-25"/>
						</button>
						<h2 class="text-28 font-bold pb-40 pt-20">Urmează pașii de mai jos:</h2>
						<div class="text-16 flex pb-20">
							<div class="bg-turquoise w-25 h-25 inline-flex flex-shrink-0 justify-center items-center rounded-full font-bold mt-5">
								1
							</div>
							<div class="pl-20">
								<p class="inline">
									Apasă butonul de <span class="font-bold pr-15">Share</span>
								</p>
								<img class="icons-ios inline" alt="" src="@/assets/ios_share_black_24dp.svg">
								<p>din banda de jos a browser-ului tău <span class="font-bold">Safari</span>.</p>
							</div>
						</div>
						<div class="text-16 flex">
							<div class="bg-turquoise w-25 h-25 inline-flex flex-shrink-0 justify-center items-center rounded-full font-bold mt-5">
								2
							</div>
							<div class="pl-20">
								<p class="inline">
									Scrollează până găsești "butonul Add to Home Screen"
								</p>
							</div>
						</div>
						<div class="text-16 grid justify-items-center pt-15 pb-25">
							<img class="icons-ios pb-60" alt="" src="@/assets/ios_share_black_24dp1.svg">
							<button @click="closeAddApp" class="btn btn-primary-dark">Am înțeles</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--end install app ios-->

		<!--mobile-->
		<div class="h-full">
			<!-- <div class="rotate-device items-center justify-center text-white">
				<div>
					<div class="mb-30">
						<img class="mx-auto" alt="" src="@/assets/rotate-device.png">
					</div>
					<div class="px-40 text-center">
						Pentru a vizualiza conținutul aplicației mai în detaliu<br>
						te rugăm să întorci dispozitivul în modul portrait.
					</div>
				</div>
			</div> -->

			<AppHeader v-if="showAppHeader && !showAgeGate" />

			<router-view v-if="!showAgeGate" />

			<!--age gate-->
			<div v-show="showAgeGate" class="container-age-gate">
				<div class="container-custom-desk">
					<img class="block mx-auto w-[158px] h-auto mb-25"  alt="Iqos Pocket" src="@/assets/logo-redesign.svg" />
					<div class="font-iqos font-bold text-21 md:text-[28px] leading-snug mb-35 md:mb-40">
						 Acest website este destinat persoanelor cu vârsta peste 18 ani, fumătoare și rezidente în România.
					</div>

					<!-- <div class="mb-5">
						<button @click="minorAgeGate = true" class="btn btn--primary mx-4 py-3 py-md-4 px-5" type="button">NU</button>
						<button @click="setAgeGate" class="btn btn--primary mx-4 py-3 py-md-4 px-5" type="button">DA</button>
					</div> -->

					<form @submit.prevent="checkAge">
				        <div class="flex">
				            <div class="w-1/2 pr-10">
				                <label for="age_gate_month" class="form-label is-dropdown -processed" :class="{' is-filled': ageMonth !== '', 'is-error': ageMonthError }">
				                    <select id="age_gate_month" name="age_gate_month" class="form-select" aria-labelledby="label-age_gate_month" v-model.number="ageMonth" @change="ageMonthError = false">
				                        <option selected="" hidden="" disabled=""></option>
				                        <option value="0">Ianuarie</option>
				                        <option value="1">Februarie</option>
				                        <option value="2">Martie</option>
				                        <option value="3">Aprilie</option>
				                        <option value="4">Mai</option>
				                        <option value="5">Iunie</option>
				                        <option value="6">Iulie</option>
				                        <option value="7">August</option>
				                        <option value="8">Septembrie</option>
				                        <option value="9">Octombrie</option>
				                        <option value="10">Noiembrie</option>
				                        <option value="11">Decembrie</option>
				                    </select>
				                    <div class="label-desc text-left" id="label-age_gate_month">
				                        <span>Luna</span>
				                        <b></b>
				                    </div>
				                </label>
				            </div>
				            <div class="w-1/2 pl-10">
				                <label for="age_gate_year" class="form-label is-dropdown -processed" :class="{' is-filled': ageYear !== '', 'is-error': ageYearError }">
				                    <select id="age_gate_year" name="age_gate_year" class="form-select" aria-labelledby="label-age_gate_year" v-model.number="ageYear" @change="ageYearError = false">
				                        <option selected="" hidden="" disabled=""></option>
				                        <option v-for="year in ageYears" :value="year">{{ year }}</option>
				                    </select>
				                    <div class="label-desc text-left" id="label-age_gate_year">
				                        <span>Anul</span>
				                        <b></b>
				                    </div>
				                </label>
				            </div>
				        </div>
				        <div class="my-20 text-14 text-[#c73636] font-bold" v-show="isMinorError">Trebuie să ai peste 18 ani pentru a accesa acest website.</div>
					    <div class="my-30 md:mt-40 md:mb-60">
					        <button type="submit" class="btn btn-primary-dark">
					            <span>Continuă</span>
					        </button>
					    </div>
					</form>

					<div class="text-12">
						<p class="font-bold mb-20">
							Centrul de Asistență Clienți – LiveChat pe IQOS Club; zilnic, în intervalul 8:00 – 22:00.
						</p>
						<p>
							Bine ai venit pe CLUB.IQOS.ro, un website operat de Philip Morris Trading S.R.L. Aici vei putea afla mai multe despre elementele și caracteristicile produselor IQOS și lil SOLID și, de asemenea, vei putea cumpăra sistemul IQOS, componente ale sistemului IQOS și HEETS, precum și sistemul lil SOLID, componente ale sistemului lil SOLID și Fiit, pentru a fi utilizate exclusiv cu aceste sisteme. Noi tratăm foarte atent modul în care publicul larg află despre produsele noastre și, de asemenea, modul în care acesta le achiziționează. Poți accesa acest site numai dacă ai peste 18 ani, ești fumător și rezident în România. Pentru a confirma faptul că ești fumător și ai vârsta de peste 18 ani, te rugăm să folosești butonul „DA”. În caz contrar, te rugăm să folosești butonul „NU”. În continuare, în interiorul site-ului, îţi pot fi solicitate informații suplimentare cu caracter personal, în vederea achiziționării IQOS și lil SOLID, a accesoriilor IQOS și lil SOLID sau a rezervelor de tutun HEETS și Fiit.
						</p>
					</div>
				</div>
			</div>
			<!--end age gate-->

		</div>
		<!--end mobile-->

	</div>
</template>




<script>

import { mapState } from "vuex";
import { isIOS } from 'mobile-device-detect';
import AppHeader from "@/views/Partials/AppHeader";

export default {
	name: 'App',
	data () {
		return {
			IOS: isIOS,
			showAgeGate: true,
			addApp: true,
			howAddApp: false,
			currYear: (new Date()).getFullYear(),
			currMonth: (new Date()).getMonth(),
			ageMonth: '',
			ageYear: '',
			ageMonthError: false,
			ageYearError: false,
            isMinorError: false,
		}
	},
	computed: {
		...mapState(['showAppHeader']),
		ageYears() {
			var years = [];
			var minYear = this.currYear - 100;
			for(var y = this.currYear; y >= minYear; y--) {
				years.push(y);
			}
			return years;
		}
	},
	components: {
		AppHeader
	},
	watch:{
		$route (to, from){
			let routeName = this.$route.name,
				iosBar = document.querySelector('.ios-bar-install');

			if (iosBar) {
				if (routeName !== 'choose-device') {
					iosBar.classList.add('ios-bar-install--top-100');
					iosBar.classList.remove('ios-bar-install--top-56');
				} else {
					iosBar.classList.add('ios-bar-install--top-56');
					iosBar.classList.remove('ios-bar-install--top-100');
				}
			}
		}
	},
    methods: {
		resize() {
			this.$store.commit('setWindowSize', document.documentElement);
		},
		liftAgeGate() {
			this.$cookies.set('iqos-age-verified', 'yes');
			this.showAgeGate = false;
		},
		closeAddApp() {
			this.$cookies.set('iqos-close-add-app', 'yes');
			this.addApp = false;
			this.howAddApp = false;
		},
		checkAge () {
			this.ageMonthError = false;
			this.ageYearError = false;
			this.isMinorError = false;
			if (this.ageMonth === '') {
				this.ageMonthError = true;
			}
			if (this.ageYear === '') {
				this.ageYearError = true;
			}
			if (this.ageMonth === '' || this.ageYear === ''){
				return false;
			}
			if (this.currYear - this.ageYear > 18) {
				this.liftAgeGate();
			}
			else if ((this.currYear - this.ageYear === 18) && (this.currMonth >= this.ageMonth)) {
				this.liftAgeGate();
			}
			else {
				this.isMinorError = true;
			}
		}
	},
	beforeMount() {
        this.resize();
	},
	created() {

		//detects if device is in standalone mode
		var isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

		//checks if should display install popup notification install app
		if (this.$cookies.isKey('iqos-close-add-app') || (isIOS && isInStandaloneMode())) {
			this.addApp = false;
		}

		//if cookie "age gate" is set = hide age gate
		if (this.$cookies.isKey('iqos-age-verified')) {
			this.showAgeGate = false;
		}

	},
	mounted() {
		window.addEventListener('resize', this.resize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resize);
	}
}

</script>
