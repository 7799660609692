<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.421" height="12.828" viewBox="0 0 17.421 12.828">
        <path d="M10,10l5-5L10,0m4,5.1L0,5" transform="translate(16.414 11.414) rotate(180)" fill="none" stroke="#34303d" stroke-linecap="round" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'IconArrowLeft'
};
</script>
