<template>
    <div class="back-device px-30 text-left">
        <div class="mx-auto max-w-[1100px] h-full">
            <a
                href="#"
                class="inline-flex items-center h-full"
                @click.prevent="click"
            >
                <icon-arrow-left class="inline-block mr-10"></icon-arrow-left>
                <span class="inline-block">{{ text }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import IconArrowLeft from "@/views/Partials/IconArrowLeft";

export default {
    name: 'BackButton',
    components: {
        IconArrowLeft
    },
    props: {
        to: {
            type: [Object, Boolean],
            default: false // false or an vue router route Object
        },
        back: {
            type: Boolean,
            default: false // if "to" is false and "back" is true it will just take you "back"
        },
        text: {
            type: String,
            default: 'Înapoi'
        },
        actions: {
            type: Array,
            default() { return []; } // example: [{ name: 'backToVideos' }, { name: 'etc'}]
        }
    },
    methods: {
        click() {
            this.actions.forEach(action => {
                this.$store.dispatch(action.name, action.payload);
            });
            if (this.to) {
                this.$router.push(this.to);
            }
            else if (this.back) {
                this.$router.go(-1);
            }
        }
    }
};
</script>
