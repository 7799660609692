<template>
<div
	@click="playVideo('video-' + (index+1)); makeVideoLandscape()"
	class="video-item"
	:class="'video-item--' + (index+1)"
>
	<div @click="videoTrack" class="flex items-center h-full">
		<div class="w-2/5 self-stretch relative overflow-hidden">
			<img class="absolute z-10 top-0 left-0 w-full h-full object-cover object-center" :src="require('@/assets/thumbs/' + device.name + '/' + video.type + '.jpg')" alt="">
			<icon-play class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 block mx-auto"></icon-play>
		</div>
		<div class="w-3/5 pl-25 pr-30 text-left text-16">
			<div>{{ videoTitle }}</div>
		</div>
	</div>
	<div class="video-item__video-container">
		<video
			@click.stop="noOp"
			@webkitfullscreenchange="stopFull($event)"
			@mozfullscreenchange="stopFull($event)"
			@msfullscreenchange="stopFull($event)"
			@fullscreenchange="stopFull($event)"
			@ended="ended($event)"
			class="video-item__video"
			:id="'video-' + (index+1)"
			controls
			allowfullscreen
			controlsList="nodownload"
			disablePictureInPicture
			muted
			>
			<!-- <source v-if="!this.$store.state.isMob || video.srcPortrait === undefinied"
				:src="video.src"
			/>
            <source v-else
                :src="video.srcPortrait"
            /> -->
			<source :src="!this.$store.state.isMob || video.srcPortrait === undefined ? video.src : video.srcPortrait">
		</video>
	</div>
</div>
</template>

<script>
import config from "@/config";
import IconPlay from "@/views/Partials/IconPlay";

import { mapActions } from "vuex";

export default {
	name: "VideoItem",
	props: ['device', 'video', 'index'],
	components: {
		IconPlay
	},
	computed: {
		videoTitle() {
			if (this.video) return config.videoTexts.find(text => (text.type === this.video.type)).title;
		}
	},

	methods: {
		makeVideoLandscape() { //!Changed orientation lock to portrait on mobiles
            var deviceName = this.$route.params.deviceName;
            // console.log('device name:', deviceName);
			if (deviceName === 'iqos-originals-one' || deviceName === 'iqos-originals-duo' || deviceName === 'lil-solid' || deviceName === 'iqos-iluma' || deviceName === 'iqos-iluma-prime'  || deviceName === 'diferente'  || deviceName === 'sfaturi' || deviceName === 'iqos-iluma-one') {
				if (screen.orientation && screen.orientation.lock) {
					screen.orientation.lock('portrait');
				}
			}
		},
		noOp() {},
		...mapActions({
			playVideo: "playVideo",
			ended: "ended",
			stopFull: "stopFull"
		}),
		videoTrack() {
			dataLayer.push({
				'event': 'gtmZoneEvent',
				'eventCategory': 'iqos_pocket',
				'eventAction': `click_video | ${this.videoTitle}`,
				'eventLabel': `${this.device.name}`
			});
		}
	}
};
</script>
