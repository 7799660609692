<template>
    <div class="faq__container">
        <BackButton :to="{ path: '/alege-dispozitiv' }" text="Înapoi la dispozitivele IQOS" />

        <div class="faq-wrap">
            
            <div class="w-full flex flex-wrap">

                <div class="w-full text-center">
                    <h1 v-text="activeCategory.categoryTitle" class="faq__h font-bold text-24 mb-40"></h1>
                </div>

                <div class="w-full md:w-2/5 mb-30 md:pr-60">
                    <div class="page-side-nav">
                        <nav class="inner-page-navigation">
                            <div class="nav-wrapper" :class="{ 'expanded': sidenavExpanded }" id="nav-wrapper">
                                <ul>
                                    <li 
                                        @click="faqSidebarTrack(category)"
                                        v-for="category in faq"
                                        :key="category.categoryId"
                                        :class="{ 'active': category === activeCategory }"
                                    >
                                        <router-link :to="{ path: '/faq/' + category.categoryId }">{{ category.categoryTitle }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <button @click="sidenavExpanded = !sidenavExpanded" type="button" class="nav-toggle" :class="{ 'active': sidenavExpanded }" :aria-expanded="sidenavExpanded ? 'true' : 'false'" v-text="activeCategory.categoryTitle"></button>
                        </nav>
                    </div>
                </div>

                <div class="w-full md:w-3/5">
                    <accordion 
                        @click.native="faqItemTrack(question)"
                        v-for="(question, i) in activeCategory.categoryQuestions"
                        type="arrow"
                        group="group"
                        :key="activeCategory.categoryId + '--' + i"
                    >
                        <template v-slot:accTitle>
                            <div v-html="question.title"></div>
                        </template>
                        <template v-slot:accContent>
                            <div v-html="question.content"></div>
                        </template>
                    </accordion>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import BackButton from "@/views/Partials/BackButton";
import Accordion from "@/views/Partials/Accordion";
import { mapState } from "vuex";

export default {
    name: 'FaqCategory',
    components: {
        BackButton,
        Accordion
    },
    data: function() {
        return {
            sidenavExpanded: false
        };
    },
    methods: {
        faqSidebarTrack(category) {
			dataLayer.push({
                'event': 'gtmZoneEvent', 
                'eventCategory': 'iqos_pocket', 
                'eventAction': `click_faq_sidebar | ${category.categoryTitle}`,
                'eventLabel': `${category.categoryTitle}`
			});
		},
        faqItemTrack(question) {
            dataLayer.push({
                'event': 'gtmZoneEvent', 
                'eventCategory': 'iqos_pocket', 
                'eventAction': `click_question | ${question.title}`,
                'eventLabel': `${this.$route.params.categoryId}`
            });
        }
    },
    computed: {
        ...mapState(['faq']),
        categoryId () {
            return this.$route.params.categoryId;
        },
        activeCategory () {
            return this.faq.find(cat => cat.categoryId === this.categoryId);
        }
    },
    watch: {
        activeCategory() {
            this.sidenavExpanded = false;
        }
    },
    mounted() {
        this.$store.commit('setProp', { prop: 'showAppHeader', value: true });
    }
};

</script>
