<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44">
        <g transform="translate(0.401 0.108)">
            <ellipse cx="22.5" cy="22" rx="22.5" ry="22" transform="translate(-0.401 -0.108)" fill="#fffdfb"/><path d="M8.441,0l8.441,14.58H0Z" transform="translate(31.281 13.1) rotate(90)" fill="#34303d"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'IconPlay'
};
</script>
