<template>
	<div class="container-custom container-custom--hts">
	    <figure class="pt-50 mb-5">
			<img class="mx-auto object-contain items-center" alt="Iqos Pocket" src="@/assets/logo-redesign.svg" />
		</figure>
		<div class="ip-step ip-step--how text-center items-center">
			<carousel ref="carouselHowToUse" :per-page="1" :mouse-drag="true" :speed="600" class="pt-40 md:pt-100">
				<slide class="ip-step__slide">
					<div class="ip-step__slide-inner">
						<figure class="how-img how-img--1">
							<img v-if="isMobile1" alt="Iqos Pocket" src="@/assets/how/1-v3.png" />
							<img v-else alt="Iqos Pocket" src="@/assets/how/1-desk-v3.png"/>
						</figure>
						<div class="container-custom-desk how-det intro">
							<p v-if="isMobile1" class="align-self-center w-full m-0 text-18">
								Adu-ți kit-ul IQOS sau lil SOLID lângă telefon și replica mișcările după sau în timpul videourilor.
							</p>
							<p v-else class="align-self-center w-full m-0 text-18">
								Adu-ți kitul IQOS sau lil SOLID aproape și replică mișcările după sau în timpul videourilor.
							</p>
						</div>
						<div class="w-full">
							<div class="grid grid-cols-2">
								<div class="pt-10 pr-80">
									<button @click="routeAndTrack('choose-device', 'alege-dispozitiv', 1, 'click_sari_peste')" class="link no-underline">
										<u class="no-underline text-16">Sari peste</u>
									</button>
								</div>
								<div class="pr-20">
									<button
										class="btn btn-primary-dark btn--lg text-16"
										@click="slideControl('next', 1)"
										type="button"
									>
										Mai departe
									</button>
								</div>
							</div>
						</div>
					</div>
				</slide>
				<slide class="ip-step__slide">
					<div class="ip-step__slide-inner">
						<figure class="how-img how-img--2">
							<img class="img-fluid" alt="Iqos Pocket" src="@/assets/how/2-v3-2.gif" />
						</figure>
						<div class="container-custom-desk how-det intro">
							<p class="align-self-center w-full m-0 text-18">
								Alege apoi din meniul dedicat dispozitivul despre care vrei să afli mai multe.
							</p>
						</div>
						<div class="w-full">
							<div class="grid grid-cols-2">
								<div class="pt-10 pr-80">
									<button @click="routeAndTrack('choose-device', 'alege-dispozitiv', 2, 'click_sari_peste')" class="link no-underline">
										<u class="no-underline text-16">Sari peste</u>
									</button>
								</div>
								<div>
									<button
										class="btn btn-primary-dark btn--lg text-16"
										@click="slideControl('next', 2)"
										type="button"
									>
										Mai departe
									</button>
								</div>
							</div>
						</div>
					</div>
				</slide>
				<slide class="ip-step__slide">
					<div class="ip-step__slide-inner">
						<figure class="how-img how-img--3">
							<img alt="Iqos Pocket" src="@/assets/how/3-v3.png" />
						</figure>
						<div class="container-custom-desk how-det intro">
							<p class="align-self-center w-full m-0 text-18">
								Pune pauză sau reia orice secvență din videouri pentru a surprinde orice detaliu de folosire.
							</p>
						</div>
						<div class="w-full">
							<button @click="routeAndTrack('choose-device', 'alege-dispozitiv', 3, 'click_foloseste_iqos_pocket')" class="btn btn-primary-dark btn--lg text-16">
								Folosește IQOS POCKET
							</button>
						</div>
					</div>
				</slide>
			</carousel>
		</div>
	</div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { Carousel, Slide } from "vue-carousel";
export default {
	name: "HowToUse",
	data:function() {
        return {
           isMobile1 : (window.innerWidth < 768),
        }
    },
	components: {
		Carousel,
		Slide
	},
	methods: {
		tracking(clickUrl, stepLink, eventAction) {
			dataLayer.push({
				'event': 'gtmZoneEvent', 
				'eventCategory': 'iqos_pocket', 
				'eventAction': eventAction, 
				'eventLabel': `From tutorial step ${stepLink} to ${clickUrl}`
			});
		},
		routeAndTrack(route, clickUrl, stepLink, eventAction) {
			this.tracking(clickUrl, stepLink, eventAction);
			this.$router.push({ 'name': route  });
		},
		slideControl (action, step) {
			dataLayer.push({
				'event': 'gtmZoneEvent', 
				'eventCategory': 'iqos_pocket', 
				'eventAction': 'click_mai_departe', 
				'eventLabel': `from tutorial pas ${step} to: tutorial pas ${step+1}` 
			});
			let page = 0
			if (action === 'next') {
				page = this.$refs.carouselHowToUse.getNextPage()
			} else {
				page = this.$refs.carouselHowToUse.getPreviousPage()
			}
			this.$refs.carouselHowToUse.goToPage(page)
		}
		
	},
	mounted() {
		this.$store.commit('setProp', { prop: 'showAppHeader', value: false });
	},
		
};
</script>
