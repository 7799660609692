export default {
	devices: [
		{

			name: 'iqos-iluma-prime',
			title: 'IQOS ILUMA PRIME',
			device: true,
			isOld: false,
			isIluma: true,
			itemsPerRow: 3,
			videos: [

				{
					type: 'charging',
                    src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410798_PT1_IQOS4_ILUMA_Premium_ROW_How_to_Charging_723_A_1line_amber_1920x1080_ro-RO_88_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410799_PT1_IQOS4_ILUMA_Premium_ROW_How_to_Charging_723_A_1line_amber_1080x1920_ro-RO_88_AE001.mp4'
				},
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410803_PT1_IQOS4_ILUMA_Premium_ROW_How_to_Quickstart_720_A_1line_amber_1920x1080_ro-RO_105_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410804_PT1_IQOS4_ILUMA_Premium_ROW_How_to_Quickstart_720_B_1line_amber_1080x1920_ro-RO_105_AE001.mp4'
				},
				{
					type: 'customize',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410792_IQOS4_ILUMA_ROW_Quick_tips_Accessories_730_A_ro_RO_HD1080_25fps_61_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410793_IQOS4_ILUMA_ROW_Quick_tips_Accessories_730_B_ro_RO_1080x1920_25fps_61_AE001.mp4'
				},
				{
					type: 'consumables',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412003_PT1_IQOS4_ILUMA_ROW_How_to_Consumables_727_A_1line_amber_1920x1080_ro_RO_83_25p_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412004_PT1_IQOS4_ILUMA_ROW_How_to_Consumables_727_B_1line_amber_1080x1920_ro_RO_83_25p_AE001.mp4'
				},
			],
		},
		{
			name: 'iqos-iluma',
			title: 'IQOS ILUMA',
			device: true,
			isOld: false,
			isIluma: true,
			itemsPerRow: 3,
			videos: [

				{
					type: 'charging',
                    src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410800_PT1_IQOS4_ILUMA_Mid_ROW_How_to_Charging_724_A_1line_amber_1920x1080_ro-RO_82_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410801_PT1_IQOS4_ILUMA_Mid_ROW_How_to_Charging_724_B_1line_amber_1080x1920_ro-RO_86_AE001.mp4'
				},
				{
					type: 'setup',
                    src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410807_PT1_IQOS4_ILUMA_Mid_ROW_How_to_Quickstart_721_A_1line_amber_HD1080_ro_RO_96_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410810_PT1_IQOS4_ILUMA_Mid_ROW_How_to_Quickstart_721_B_1line_amber_1080x1920_ro_RO_96_AE001.mp4'
				},
				{
					type: 'customize',
                    src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410792_IQOS4_ILUMA_ROW_Quick_tips_Accessories_730_A_ro_RO_HD1080_25fps_61_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410793_IQOS4_ILUMA_ROW_Quick_tips_Accessories_730_B_ro_RO_1080x1920_25fps_61_AE001.mp4'
				},
				{
					type: 'consumables',
                    src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412003_PT1_IQOS4_ILUMA_ROW_How_to_Consumables_727_A_1line_amber_1920x1080_ro_RO_83_25p_AE001.mp4',
                    srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412004_PT1_IQOS4_ILUMA_ROW_How_to_Consumables_727_B_1line_amber_1080x1920_ro_RO_83_25p_AE001.mp4'
				},
			],
		},
		
		{

			name: 'iqos-iluma-one',
			title: 'IQOS ILUMA ONE',
			device: true,
			isOld: false,
			isIluma: false,
			itemsPerRow: 3,
			videos: [
		
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B422310_PT1_IQOS4_ILUMA_ONE_How_to_Charge_comp.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B422311_PT1_IQOS4_ILUMA_ONE_How_to_Charge_portrait_comp.mp4'
				},
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B422312_PT1_IQOS4_ILUMA_ONE_How_to_Quickstart_comp.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B422313_PT1_IQOS4_ILUMA_ONE_How_to_Quickstart_portrait_comp.mp4'
				},
			],
		},

		{
			name: 'iqos-originals-duo',
			title: 'IQOS ORIGINALS DUO',
			device: true,
			isOld: false,
			chooseNr: 1,
			itemsPerRow: 3,
			videos: [
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/IQOS+ORIGINALS+DUO/IQOS_ORIGINALS_DUO_SetUp.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/IQOS+ORIGINALS+DUO/IQOS_ORIGINALS_DUO_Charge.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/IQOS+ORIGINALS+DUO/IQOS_ORIGINALS_DUO_CleanUp.mp4'
				},
			],
		},


		{
			name: 'iqos-originals-one',
			title: 'IQOS ORIGINALS ONE',
			device: true,
			isOld: false,
			chooseNr: 1,
			itemsPerRow: 3,
			videos: [
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/IQOS+ORIGINALS+ONE/IQOS_ORIGINALS_ONE_SetUp.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/IQOS+ORIGINALS+ONE/IQOS_ORIGINALS_ONE_Charge.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/IQOS+ORIGINALS+ONE/IQOS_ORIGINALS_ONE_CleanUp.mp4'
				},
			],
		},

		{
			name: 'lil-solid',
			title: 'lil SOLID 2.0',
			device: true,
			isOld: false,
			chooseNr: 1,
			itemsPerRow: 3,
			videos: [
				// {
				// 	type: 'unboxing',
				// 	src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/lil+SOLID/LIL_SOLID_Unboxing.mp4'
				// },
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/lil+SOLID/LIL_SOLID_SetUp.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/lil+SOLID/LIL_SOLID_Charge.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/lil+SOLID/LIL_SOLID_CleanUp.mp4'
				},
			],
		},

		{
			name: 'iqos-3-duo',
			title: 'IQOS 3 DUO',
			device: true,
			showBannerWide: true,
			isOld: true,
			chooseNr: 1,
			itemsPerRow: 3,
			videos: [
				{
					type: 'unboxing',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_Unboxing.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_Charge.mp4'
				},
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_SetUp.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_CleanUp.mp4'
				},
				{
					type: 'claim-heets',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_ClaimHeets.mp4'
				},
				{
					type: 'identify-code',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_IdentifyCode.mp4'
				},
				{
					type: 'pen-serial-no',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_PenSerialNo.mp4'
				},
				{
					type: 'charger-serial-no',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3+DUO/IQOS_3_DUO_ChargerSerialNo.mp4'
				},
			],
		},

		{
			name: 'iqos-3-multi',
			title: 'IQOS 3 Multi',
			device: true,
			isOld: true,
			chooseNr: 2,
			itemsPerRow: 2,
			videos: [
				{
					type: 'unboxing',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+Multi/IQOS_MULTI_Unboxing.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+Multi/IQOS_MULTI_Charge.mp4'
				},
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+Multi/IQOS_MULTI_SetUp.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+Multi/IQOS_MULTI_CleanUp.mp4'
				},
			],
		},

		{
			name: 'iqos-2-4-plus',
			title: 'IQOS 2.4+',
			device: true,
			isOld: true,
			showBannerWide: true,
			chooseNr: 3,
			itemsPerRow: 2,
			videos: [
				{
					type: 'unboxing',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/Noul+IQOS+2.4%2B/Noul_IQOS_2_4_Unboxing.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_Charge.mp4'
				},
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_SetUp.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/Noul+IQOS+2.4%2B/Noul_IQOS_2_4_CleanUp.mp4'
				},
			],
		},

		{
			name: 'iqos-3',
			title: 'IQOS 3',
			device: true,
			isOld: true,
			chooseNr: 4,
			itemsPerRow: 2,
			videos: [
				{
					type: 'unboxing',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3/IQOS_3_Unboxing.mp4'
				},
				{
					type: 'charging',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3/IQOS_3_Charge.mp4'
				},
				{
					type: 'setup',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3/IQOS_3_Setup.mp4'
				},
				{
					type: 'cleaning',
					src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+3/IQOS_3_CleanUp.mp4'
				},
			],
		},

		// { //hide iqos 2.4+
		// 	name: 'iqos-2-4-plus',
		// 	title: 'IQOS 2.4 +',
		// 	isOld: true,
		// 	chooseNr: 5,
		// 	itemsPerRow: 2,
		// 	videos: [
		// 		{
		// 			type: 'unboxing',
		// 			src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_Unboxing.mp4'
		// 		},
		// 		{
		// 			type: 'charging',
		// 			src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_Charge.mp4'
		// 		},
		// 		{
		// 			type: 'setup',
		// 			src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_SetUp.mp4'
		// 		},
		// 		{
		// 			type: 'cleaning',
		// 			src: 'https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_CleanUp.mp4'
		// 		},
		// 	],
		// },

		{
			name: 'diferente',
            title: 'Diferențele dintre IQOS ILUMA și dispozitivele anterioare',
			device: false,
            isOld: false,
            isIluma: true,
            itemsPerRow: 3,
			videos: [
				{
					type: 'differences1',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412362_PT1_IQOS4_ILUMA_ROW_How_to_3_Duo_vs_ILUMA_725_A_1line_amber_ro_RO_HD1080_105_25p_AE001.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412363_PT1_IQOS4_ILUMA_ROW_How_to_3_Duo_vs_ILUMA_725_A_1line_amber_1080x1920_ro_RO_105_25p_AE001.mp4'
				},
				{
					type: 'differences2',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412357_PT1_IQOS4_ILUMA_ROW_How_to_2_4_Plus_vs_ILUMA_726_A_1line_amber_ro_RO_HD1080_103_25p_AE001.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412358_PT1_IQOS4_ILUMA_ROW_How_to_2_4_Plus_vs_ILUMA_726_A_1line_amber_1080x1920_ro_RO_103_25p_AE001.mp4'
				},
			]

		},
		{
			name: 'diferente-iqos3duo',
			title: 'Diferențele dintre IQOS ILUMA și IQOS 3 DUO',
			device: false,
			videos: [
				{
					type: 'differences1',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412362_PT1_IQOS4_ILUMA_ROW_How_to_3_Duo_vs_ILUMA_725_A_1line_amber_ro_RO_HD1080_105_25p_AE001.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412363_PT1_IQOS4_ILUMA_ROW_How_to_3_Duo_vs_ILUMA_725_A_1line_amber_1080x1920_ro_RO_105_25p_AE001.mp4'
				},
			]

		},
		{
			name: 'diferente-iqos24plus',
			title: 'Diferențele dintre IQOS ILUMA și IQOS 2.4 PLUS',
			device: false,
			videos: [
				{
					type: 'differences2',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412357_PT1_IQOS4_ILUMA_ROW_How_to_2_4_Plus_vs_ILUMA_726_A_1line_amber_ro_RO_HD1080_103_25p_AE001.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B412358_PT1_IQOS4_ILUMA_ROW_How_to_2_4_Plus_vs_ILUMA_726_A_1line_amber_1080x1920_ro_RO_103_25p_AE001.mp4'
				},
			]

		},
		{
			name: 'sfaturi',
			title: 'Sfaturi utile pentru o experiență completă',
			device: false,
			videos: [
				{
					type: 'customize1',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410365_IQOS4_ILUMA_ROW_Quick_tips_Personalise_729_A_ro_RO_HD1080_79_25p_AE001.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410366_IQOS4_ILUMA_ROW_Quick_tips_Personalise_729_B_ro_RO_1080x1920_87_25p_AE001.mp4',
				},
				{
					type: 'simplify',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410361_PT1_IQOS4_ILUMA_ROW_Quick_tips_Autostart_728_A_1line_amber_1920x1080_ro-RO_66_AE001.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410363_PT1_IQOS4_ILUMA_ROW_Quick_tips_Autostart_728_A_1line_amber_1080x1920_ro-RO_66_AE001.mp4'
				},
				{
					type: 'connect',
					src: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410368_ IQOS4_ILUMA_ROW_How_to_Connect_to_App_722_A_ro_RO_63_HD1080_25fps_AE002.mp4',
					srcPortrait: 'https://iqos-pocket.s3.eu-west-1.amazonaws.com/Iluma/B410369_ IQOS4_ILUMA_ROW_How_to_Connect_to_App_722_B_ro_RO_63_1080x1920_25fps_AE002.mp4'
				},
			]

		}
	],

	videoTexts: [
		{
			type: 'unboxing',
			name: 'ce-contine-kit-ul-tau-iqos',
			title: 'Ce conține kit-ul tău IQOS',
		},
		{
			type: 'charging',
			name: 'cum-sa-ti-incarci-dispozitivul',
			title: 'Cum să-ți încarci dispozitivul',
		},
		{
			type: 'setup',
			name: 'cum-sa-folosesti-dispozitivul',
			title: 'Cum să folosești dispozitivul',
		},
		{
			type: 'cleaning',
			name: 'cum-sa-ti-cureti-dispozitivul',
			title: 'Cum să-ți cureți dispozitivul',
		},
		{
			type: 'claim-heets',
			name: 'cum-revendici-pachetele-heets-din-iqos-club',
			title: 'Cum revendici pachetele HEETS din IQOS CLUB',
		},
		{
			type: 'identify-code',
			name: 'cum-identifici-codul-din-interiorul-pachetului-de-heets',
			title: 'Cum identifici codul din interiorul pachetului de HEETS',
		},
		{
			type: 'pen-serial-no',
			name: 'cum-afli-seria-stiloului-tau-iqos',
			title: 'Cum afli seria stiloului tău IQOS',
		},
		{
			type: 'charger-serial-no',
			name: 'cum-afli-seria-incarcatorului-tau-iqos',
			title: 'Cum afli seria încărcătorului tău IQOS',
		},
		{
			type: 'customize',
			name: 'cum-sa-ti-personalizezi-dispozitivul',
			title: 'Cum să-ți personalizezi dispozitivul',
		},
		{
			type: 'consumables',
			name: 'cu-ce-rezerve-sa-folosesti-dispozitivul',
			title: 'Cu ce rezerve să folosești dispozitivul',
		},
		{
			type: 'differences1',
			name: 'cum-se-deosebeste-iqos-iluma-fata-de-iqos-3-duo',
			title: 'Cum se deosebește IQOS ILUMA față de IQOS 3 DUO',
		},
		{
			type: 'differences2',
			name: 'cum-se-deosebeste-iqos-iluma-fata-de-iqos-2-4-plus',
			title: 'Cum se deosebește IQOS ILUMA față de IQOS 2.4 PLUS',
		},
		{
			type: 'customize1',
			name: 'cum-sa-ti-personalizezi-rapid-dispozitivul-iqos-iluma',
			title: 'Cum să-ți personalizezi rapid dispozitivul IQOS ILUMA',
		},
		{
			type: 'simplify',
			name: 'cum-sa-ti-simplifici-experienta-iqos-iluma',
			title: 'Cum să-ți simplifici experiența IQOS ILUMA',
		},
		{
			type: 'connect',
			name: 'cum-sa-ti-conectezi-dispozitivul-iqos-iluma-la-iqos-connect',
			title: 'Cum să-ți conectezi dispozitivul IQOS ILUMA la IQOS Connect',
		},


	],

	banners: [
		{
			id: 1,
			isWide: false,
			img: 'b-1',
			imgPrime: 'b-1',
			title: 'Află prin ce se deosebește IQOS ILUMA de restul dispozitivelor IQOS!'
		},
		{
			id: 2,
			isWide: false,
			img: 'b-2',
			imgPrime: 'b-2-prime',
			titleW: 'Cum să te bucuri',
			title: 'de o experiență IQOS superioară'
		},
		{
			id: 3,
			isWide: true,
			img1: 'b-3',
			img2: 'b-3-desktop',
			titleW: 'Diferențele dintre IQOS ILUMA',
			title: 'și dispozitivele anterioare',
			descr: 'Urmărește clipul și află ce aduce nou IQOS ILUMA!'
		},
	],



}