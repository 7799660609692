<template>
	<div class="container-custom">
		<BackButton :to="{ name: 'choose-device' }" text="Schimbă dispozitivul" />

		<div class="ip-step ip-step--videos text-center">

			<div class="mx-auto max-w-[1100px]">
				<h2 v-text="device.title" class="text-24 px-10 font-bold mb-25 text-left md:text-center max-w-[420px] mx-auto"></h2>
				<div class="flex flex-wrap">
					<div class="w-full sm:w-1/2 md:w-1/3 p-8 sm:p-12"
						v-for="(video, index) in device.videos"
						ref="vids"
						:key="video.type"
					>
						<video-item
							:device="device"
							:video="video"
							:index="index"
						></video-item>
					</div>
				</div>
				<div>
					<banner :device="device" />
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import BackButton from "@/views/Partials/BackButton";
import VideoItem from "@/views/Partials/VideoItem";
import Banner from "@/views/Partials/Banner";
import config from "@/config";

export default {
	name: "Device",
	props: ['deviceName'],
	components: {
		BackButton,
		VideoItem,
		Banner
	},
	computed: {
		device() {
			return config.devices.find(d => d.name === this.deviceName);
		},
		currentVideo() {
			var index = this.$store.state.currentVideoIndex;
			if (index !== null && this.device) {
				return this.device.videos[currentVideoIndex];
			}
		}
    },
    methods: {
        detectDevice() {
            this.$store.commit('detectDevice')
            // console.log(this.$store.state.isMob)
        }
    },
	mounted () {
        this.detectDevice();
        this.$store.commit('setProp', { prop: 'showAppHeader', value: true });

		TweenMax.staggerFromTo(this.$refs['vids'], .5, { opacity: 0, y: 20 }, { opacity: 1, y: 0, delay: 0.2}, 0.1);
	}
};
</script>
