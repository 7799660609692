<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.421" height="12.828" viewBox="0 0 17.421 12.828">
        <path d="M11.4,11l5-5-5-5m4,5.1L1.4,6" transform="translate(-0.393 0.414)" fill="none" stroke="#34303d" stroke-linecap="round" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: 'IconArrowRight'
};
</script>
